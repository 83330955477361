import theme from "theme/theme";
import { Box, styled } from "@mui/system";

const CustomBox = styled(Box)(({ theme }) => ({
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F6F7F9",
    [theme.breakpoints.down("sm")]: {
        maxWidth: "100vw",
        overflowX: "hidden",
        marginTop: theme.spacing(2),
    },
    [theme.breakpoints.between("md", "lg")]: {
        maxWidth: "1080px",
        marginTop: theme.spacing(6),
    },
    [theme.breakpoints.up("lg")]: {
        maxWidth: "1440px",
        marginTop: theme.spacing(6),
    },
}));

export default CustomBox;
