import { Label, SelectField, TextAreaField, TextField } from "@aws-amplify/ui-react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import {
    Box,
    Card,
    CardContent,
    Dialog,
    IconButton,
    Stack,
    Typography,
    styled,
} from "@mui/material";
import React, { useEffect } from "react";
import { GreenButton, WhiteButton } from "../components/Buttons";
import LoadingSpinner from "../components/LoadingSpinner";
import { ScheduleAddress } from "../components/StoreItems";
import { useIsMobile } from "../helpers/CustomHooks";
import {
    API_Authenticate,
    API_CreateServiceTicket,
    API_GetScheduleAddresses,
    API_GetTicketReport,
    GetUserName,
} from "../services/GetInvoiceData";
import NavBreadcrumbs from "../helpers/NavBreadcrumbs";

const ServiceRequestPageContainer = styled("div")(({ theme }) => ({
    // Add styles here.
    width: "100%",
}));
type Props = {
    address: ScheduleAddress;
    setAddress: React.Dispatch<React.SetStateAction<ScheduleAddress>>;
};

const ServiceRequestPage: React.FC<Props> = ({ address, setAddress }) => {
    const [addresses, setAddresses] = React.useState<ScheduleAddress[]>([]);
    const [addressLabel, setAddressLabel] = React.useState<string>("");
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [csono, setCsono] = React.useState<string>("");
    const [isLoadingReport, setIsLoadingReport] = React.useState(false);
    const [cpono, setCpono] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const isMobile = useIsMobile();

    const handleScheduleAddressChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        var addrStr = event.target.value;

        console.log("handleScheduleAddressChange: ", event.target.value);
        var addr;

        if (addrStr && addrStr !== "") addr = addresses.find((addr) => addr.cid === addrStr);

        if (addr) {
            setAddress(addr);
        } else {
            console.log("Address Not Found: ", addr);
            setAddress({} as ScheduleAddress);
        }
        console.log("Cart handleScheduleAddressChange", addr);
    };

    const getScheduleAddress = async (username: string) => {
        const token = await API_Authenticate();
        const addrs = await API_GetScheduleAddresses(token, username, "SVC");
        if (!addrs) {
            console.log("Error getting schedule addresses. (null)");
            return [];
        }
        if (!addrs.IsSuccessful) {
            console.log(
                "Error getting schedule addresses. (" + addrs.ErrorCode + ": " + addrs.Error + ")",
            );
            return [];
        }
        return addrs.Addresses;
    };

    const showReport = async (cuid: string, csono: string) => {
        try {
            setIsLoadingReport(true);

            const token = await API_Authenticate();
            console.log("Show Report for T: ", csono);

            await API_GetTicketReport(token, GetUserName(), csono);
        } catch (error) {
            console.error("Failed to load report: ", error);
            alert("Failed to load report: " + error);
        } finally {
            setIsLoadingReport(false);
        }
    };

    const createTicket = async () => {
        try {
            setIsSubmitting(true);

            var token = await API_Authenticate();
            var result = await API_CreateServiceTicket(
                token,
                address.ccustno,
                address.cinvno,
                cpono,
                GetUserName(),
                message,
            );
            if (!result) {
                console.log("Error creating service ticket. (null)");
                alert("Error creating service ticket. (null)");
                return;
            }
            if (!result.IsSuccessful) {
                console.log(
                    "Error creating service ticket. (" +
                        result.ErrorCode +
                        ": " +
                        result.Error +
                        ")",
                );
                alert(
                    "Error creating service ticket. (" +
                        result.ErrorCode +
                        ": " +
                        result.Error +
                        ")",
                );
                return;
            }
            setCsono(result.csono);
            setDialogOpen(true);
        } catch (error) {
            console.log("Error: ", error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const clearAll = () => {
        setCpono("");
        setMessage("");
    };

    const successComponent = () => {
        return (
            <Dialog
                open={dialogOpen}
                onClose={() => clearAll()}
                sx={{ width: isMobile ? "100%" : "665px", m: isMobile ? "1px" : "auto" }}
            >
                {isLoadingReport ?
                    <LoadingSpinner message='Retrieving order...' />
                :   <>
                        <div className='float-right'>
                            <IconButton
                                className='float-right'
                                onClick={() => setDialogOpen(false)}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <Stack
                            spacing={1}
                            direction='column'
                            sx={{
                                marginLeft: "20px",
                                marginRight: "20px",
                                marginBottom: "20px",
                                marginTop: "1px",
                            }}
                            alignItems='center'
                        >
                            <div>
                                <CheckCircleIcon sx={{ fontSize: 80, color: "#58B55D" }} />
                            </div>
                            <div className='checkout_info_label'>
                                Your request has been submitted successfully!
                            </div>
                            <div className='order_complete_order_id'>Ticket # {csono}</div>
                            <WhiteButton
                                className='white_button'
                                variant='outlined'
                                onClick={() => showReport("", csono)}
                            >
                                Print Order
                            </WhiteButton>
                        </Stack>
                    </>
                }
            </Dialog>
        );
    };

    useEffect(() => {
        if (!dialogOpen && !isLoading) {
            clearAll();
        }
    }, [dialogOpen]);

    useEffect(() => {
        if (address && address?.ccustno !== "") {
            setAddressLabel("Schedule/Address");
        } else setAddressLabel("Please select Schedule/Shipping address...");
    }, [address]);

    useEffect(() => {
        setIsLoading(true);
        try {
            getScheduleAddress(GetUserName()).then((addrs) => {
                setAddresses(addrs);
                setIsLoading(false);
            });
        } catch (error) {
            console.log("Error: ", error);
            setIsLoading(false);
        }
    }, []);

    if (isLoading) {
        return <LoadingSpinner message='Loading...' />;
    }

    if (isSubmitting) {
        return <LoadingSpinner message='Submitting service request...' />;
    }

    return (
        <Box sx={{ width: "100%", typography: "body1" }}>
            <NavBreadcrumbs currentView='servicerequests' />
            <ServiceRequestPageContainer>
                {successComponent()}
                <Stack
                    direction='column'
                    spacing={0}
                    alignItems='center'
                    justifyContent={"center"}
                    sx={{ m: isMobile ? 0 : 20 }}
                >
                    <Stack
                        direction='column'
                        spacing={2}
                        alignItems='center'
                        sx={{ marginBottom: 2, marginTop: isMobile ? 5 : 0 }}
                    >
                        <Typography variant='h5'>Service Request</Typography>
                    </Stack>

                    <Box sx={{ width: "100vw" }}>{/* This box is here for correct sizing */}</Box>

                    <Card sx={{ width: isMobile ? "100%" : "90%", maxWidth: "1440px" }}>
                        <CardContent>
                            <Stack
                                direction='column'
                                spacing={0}
                                alignItems='left'
                            >
                                <Label className='textfields_label'>Service Address</Label>
                                <SelectField
                                    width={"100%"}
                                    className='textfields'
                                    labelHidden
                                    id='sel-address'
                                    onChange={handleScheduleAddressChange}
                                    value={address?.cid}
                                    label={addressLabel}
                                    placeholder='Please select an Address...'
                                >
                                    {addresses &&
                                        addresses?.map((addr) => (
                                            <option
                                                className='search_textfields'
                                                key={addr.cid}
                                                value={addr.cid}
                                            >
                                                {addr.caddr}
                                            </option>
                                        ))}
                                </SelectField>
                                <Box sx={{ height: "20px" }} />
                                <Label className='textfields_label'>Service Information</Label>
                                <TextField
                                    value={cpono}
                                    onChange={(e) => setCpono(e.target.value)}
                                    className='textfields'
                                    labelHidden
                                    label=''
                                    placeholder='Enter PO Number'
                                />
                                <Box sx={{ height: "20px" }} />
                                <Label className='textfields_label'>
                                    Describe your service request needed for your equipment
                                </Label>
                                <TextAreaField
                                    rows={6}
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    className='textfields'
                                    labelHidden
                                    label=''
                                    placeholder=''
                                />
                                <Box sx={{ height: "20px" }} />
                                <GreenButton
                                    className='buttons'
                                    sx={{ width: "150px" }}
                                    onClick={() => createTicket()}
                                >
                                    Submit Request
                                </GreenButton>
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>
            </ServiceRequestPageContainer>
        </Box>
    );
};

export default ServiceRequestPage;
