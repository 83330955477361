import { SelectField, useAuthenticator } from "identity/helpers/imports";
import { useEffect, useState } from "react";
import { SelectAllTenants, SelectTenant, SelectUserTenants } from "../../services/GraphQlHelper";
import { Box, Button, Grid } from "@mui/material";
import theme from "theme/theme";

export type Props = {
    username: string;
    onChooseTenant: (tenantName: string) => void;
    onSignOut: () => void;
};

const TenantChooser: React.FC<Props> = (props) => {
    const [selectedTenantName, setSelectedTenantName] = useState("");
    const [tenants, setTenants] = useState<TenantSelectType[]>([]);
    const [retryCount, setRetryCount] = useState(0);

    async function GetTenantDescription(tenantid: string | undefined) {
        if (!tenantid) return "";

        const tenant = await SelectTenant(tenantid);

        console.log("GetTenantDescription: ", tenant);
        if (!tenant?.data?.tenantBySubdomain?.items[0]?.name) return "";

        return tenant?.data?.tenantBySubdomain?.items[0]?.name;
    }

    const TenantSelect = () => {
        return (
            <SelectField
                label='Choose A Tenant'
                value={selectedTenantName}
                onChange={(e) => setSelectedTenantName((e.target as HTMLSelectElement).value)}
            >
                <option
                    key={342324332}
                    value={""}
                >
                    {"Please select a tenant..."}
                </option>
                {tenants &&
                    tenants.length > 0 &&
                    tenants?.map((item, index) => (
                        <option
                            key={index}
                            value={item?.tenantid}
                        >
                            {item?.tenantdescription}
                        </option>
                    ))}
            </SelectField>
        );
    };
    type TenantSelectType = {
        tenantid: string;
        tenantdescription: string;
    };
    useEffect(() => {
        const fetchUserTenants = async () => {
            let tenants: TenantSelectType[] = [];
            console.log("fetchUserTenants: ", props.username);

            if (retryCount > 10) return;
            if (props.username === "") {
                console.log("fetchUserTenants: No username");
                return;
            }

            try {
                if (props.username === "GetAllTenants") {
                    const userTenants = await SelectAllTenants();
                    if (userTenants?.data?.listTenants?.items) {
                        for (let i = 0; i < userTenants.data.listTenants.items.length; i++) {
                            const item = userTenants.data.listTenants.items[i];
                            tenants.push({ tenantid: item.id, tenantdescription: item.name });
                        }
                    }
                    console.log("User Tenants: ", tenants);
                } else {
                    const userTenants = await SelectUserTenants(props.username);
                    if (userTenants?.data?.tenantsUsersByUsersId?.items) {
                        for (
                            let i = 0;
                            i < userTenants.data.tenantsUsersByUsersId.items.length;
                            i++
                        ) {
                            const item = userTenants.data.tenantsUsersByUsersId.items[i];
                            const desc = await GetTenantDescription(item.tenantsId);
                            if (desc)
                                tenants.push({
                                    tenantid: item.tenantsId,
                                    tenantdescription: desc,
                                });
                        }
                    }
                    console.log("User Tenants: ", tenants);
                }
            } catch (error) {
                console.log("TenantChooser: Error: ", error);
                setRetryCount(retryCount + 1);
            }
            //setUserTenants(userTenants.data);
            setTenants(tenants);
            console.log("UserName", props.username);
            console.log("tenants: ", tenants);
        };
        fetchUserTenants();
    }, [props.username, retryCount]);

    function onChooseTenant(selectedTenantName: string) {
        if (!selectedTenantName || selectedTenantName === "") {
            alert("Please select a tenant");
            return;
        }
        props.onChooseTenant(selectedTenantName);
    }

    const { toSignIn } = useAuthenticator();

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
            }}
        >
            <Box sx={{ width: "300px" }}>
                <TenantSelect />
                <Grid
                    container
                    spacing={2}
                    sx={{ mt: 2 }}
                >
                    <Grid
                        item
                        xs={6}
                    >
                        <Button
                            variant='outlined'
                            fullWidth
                            onClick={props.onSignOut}
                            sx={{
                                borderRadius: theme.shape.borderRadius,
                                backgroundColor: theme.palette.common.white,
                                boxShadow: "none",
                            }}
                        >
                            Sign Out
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                    >
                        <Button
                            variant='contained'
                            fullWidth
                            onClick={() => onChooseTenant(selectedTenantName)}
                            sx={{
                                borderRadius: theme.shape.borderRadius,
                                boxShadow: "none",
                            }}
                        >
                            Choose
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default TenantChooser;
