import {
    AccountBalanceOutlined,
    DescriptionOutlined,
    HelpOutlineOutlined,
    LocalShippingOutlined,
    PinOutlined,
} from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useIsMobile, useIsLessThanFullWidth } from "helpers/CustomHooks";
import React from "react";
import { useNavigate } from "react-router-dom";

interface DashboardBoxProps {
    icon: React.ReactElement;
    title?: string; // Not required for FAQs.
    value: string;
    buttonText: string;
    to: string;
}

const DashboardBox: React.FC<DashboardBoxProps> = ({ icon, title, value, buttonText, to }) => {
    const navigate = useNavigate();
    const isMobile = useIsMobile();
    const isLessThanFullWidth = useIsLessThanFullWidth();

    return (
        <Box
            sx={{
                width: "260px",
                height: "180px",
                bgcolor: "background.paper",
                borderRadius: "4px",
                border: "1.5px solid #DADCE0",
                p: 2.25,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center", gap: 3, mt: 1 }}>
                <Box
                    sx={{
                        bgcolor: "#58B55D",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "56px",
                        height: "56px",
                    }}
                >
                    {React.cloneElement(icon, { sx: { fontSize: 40, color: "white" } })}
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    <Typography
                        variant='h6'
                        sx={{
                            fontFamily: "Montserrat",
                            color: "#1E2832",
                            textTransform: "none",
                            fontSize: 14,
                            fontWeight: 500,
                        }}
                    >
                        {title}
                    </Typography>
                    {value && (
                        <Typography
                            variant='subtitle1'
                            sx={{
                                fontFamily: "Montserrat",
                                color: "#1E2832",
                                textTransform: "none",
                                fontSize: 20,
                                fontWeight: 600,
                            }}
                        >
                            {value}
                        </Typography>
                    )}
                </Box>
            </Box>
            <Button
                variant='contained'
                fullWidth
                onClick={() => navigate(to)}
                sx={{
                    "backgroundColor": "transparent",
                    "color": "#1E2832",
                    "fontFamily": "Montserrat",
                    "borderRadius": "6px",
                    ":hover": { backgroundColor: "rgba(255, 255, 255, 0.7)" },
                    "border": "2px solid lightgrey",
                    "boxShadow": "none",
                    "textTransform": "none",
                    "width": "211px",
                    "height": "44px",
                }}
            >
                {buttonText}
            </Button>
        </Box>
    );
};

const DashboardPage = () => {
    const isMobile = useIsMobile();
    const isLessThanFullWidth = useIsLessThanFullWidth();
    return (
        <Box sx={{ maxWidth: isMobile ? "100%" : "1440px", margin: "auto", p: 2, width: "100%" }}>
            <Typography
                variant='h4'
                sx={{
                    ml: 4,
                    fontWeight: "600",
                    fontFamily: "Montserrat",
                    fontSize: 16,
                    mb: 2,
                    mt: isMobile ? 2 : 0,
                    textAlign: isLessThanFullWidth ? "center" : "left",
                }}
            >
                Dashboard
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: isLessThanFullWidth ? "center" : "flex-start",
                    maxWidth: isLessThanFullWidth ? "100vw" : "1440px",
                    gap: "28px",
                    p: 2,
                    flexWrap: isLessThanFullWidth ? "wrap" : "nowrap",
                    overflow: "auto",
                }}
            >
                <DashboardBox
                    icon={<LocalShippingOutlined sx={{ fontSize: 40 }} />}
                    title='Total Orders'
                    value='128'
                    buttonText='View Details'
                    to='/myorders'
                />
                <DashboardBox
                    icon={<DescriptionOutlined sx={{ fontSize: 40 }} />}
                    title='Total Invoices'
                    value='$1200'
                    buttonText='Pay Invoices'
                    to='/viewinvoices'
                />
                <DashboardBox
                    icon={<PinOutlined sx={{ fontSize: 40 }} />}
                    title='Total Tickets'
                    value='80'
                    buttonText='View Tickets'
                    to='/tickets'
                />
                <DashboardBox
                    icon={<AccountBalanceOutlined sx={{ fontSize: 40 }} />}
                    title='Credit Balance'
                    value='$800'
                    buttonText='Make a Payment'
                    to='/payments'
                />
                <DashboardBox
                    icon={<HelpOutlineOutlined sx={{ fontSize: 40 }} />}
                    value='FAQs'
                    buttonText='View Questions'
                    to='/faqs'
                />
            </Box>
        </Box>
    );
};

export default DashboardPage;
