import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import { WhiteButton, GreenButton } from "./Buttons";

export interface ConfirmDialogProps {
    title: string;
    children: React.ReactNode;
    open: boolean;
    setOpen: (open: boolean) => void;
    onConfirm: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
    const { title, children, open, setOpen, onConfirm } = props;
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby='confirm-dialog'
        >
            <DialogTitle id='confirm-dialog'>{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <WhiteButton
                    variant='contained'
                    onClick={() => setOpen(false)}
                    color='secondary'
                >
                    No
                </WhiteButton>
                <GreenButton
                    variant='contained'
                    onClick={() => {
                        setOpen(false);
                        onConfirm();
                    }}
                >
                    Yes
                </GreenButton>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
