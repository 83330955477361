import React, { ReactNode } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { Category } from "../components/StoreItems";
import navItems from "./navItems";
import CustomBox from "../theme/components/Box";

type MainLayoutProps = {
    children: ReactNode;
    signOut?: any;
    showStoreBar?: boolean;
    selectedCategory?: string[];
    searchCategory?: string[];
    selectedFilter?: string;
    categories?: Category[];
    setSelectedCategory?: React.Dispatch<React.SetStateAction<string[]>>;
    setSelectedFilter?: React.Dispatch<React.SetStateAction<string>>;
    setCategories?: React.Dispatch<React.SetStateAction<Category[]>>;
    setSearchCategory?: React.Dispatch<React.SetStateAction<string[]>>;
    setOpenCart?: React.Dispatch<React.SetStateAction<boolean>>;
    setShowCartButton?: React.Dispatch<React.SetStateAction<boolean>>;
    setNumberOfCartItems?: React.Dispatch<React.SetStateAction<number>>;
    openCart?: boolean;
    showCartButton?: boolean;
    numberOfCartItems?: number;
    currentView?: string;
};

function MainLayout({ children, signOut, numberOfCartItems }: MainLayoutProps) {
    return (
        <CustomBox>
            <NavBar
                navItems={navItems}
                numberOfCartItems={numberOfCartItems}
                signOut={signOut}
            />
            {children}
            <Footer />
        </CustomBox>
    );
}

export default MainLayout;
