import { useEffect, useState } from "react";

export function useIsMobile() {
    const [width, setWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1080);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
        setIsMobile(window.innerWidth < 1080);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    return isMobile;
}

export function useIsLessThanFullWidth() {
    const [width, setWidth] = useState(window.innerWidth);
    const [isLessThanFullWidth, setIsLessThanFullWidth] = useState(window.innerWidth < 1440);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
        setIsLessThanFullWidth(window.innerWidth < 1440);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    return isLessThanFullWidth;
}

export function useScreenWidth() {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    return width;
}
