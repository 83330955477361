import { Button, Flex, TextField } from "@aws-amplify/ui-react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { experimentalStyled as styled } from "@mui/material/styles";
import * as React from "react";
import { DeleteClient, SelectAllClients } from "../services/GraphQlHelper";
import ClientsUpdateForm from "../ui-components/ClientsUpdateForm";
import { getOverrideProps } from "../ui-components/utils";
//import UpdateClientsTenants from "./UpdateClientsTenants";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
}));

interface UpdateClientProps {
    onSuccess: () => void;
}

export default function UpdateClients(props: UpdateClientProps) {
    const [selectedPage, setSelectedPage] = React.useState("default");
    const [clients, setClients] = React.useState([] as any[]);
    const [clientName, setClientName] = React.useState("");
    const [addRelationOpen, setAddRelationOpen] = React.useState(false);
    const [confirmationOpen, setConfirmationOpen] = React.useState(false);

    function UsersTable() {
        console.log("Calling UsersTable");
        return (
            <>
                <TableContainer component={Paper}>
                    <Table aria-label='simple table'>
                        <TableHead>
                            <TableRow>
                                <TableCell align='left'>
                                    <h5>Company Code</h5>
                                </TableCell>
                                <TableCell align='left'>
                                    <h5>Client</h5>
                                </TableCell>
                                <TableCell align='left'></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {clients
                                .sort((a, b) => (a > b ? 1 : -1))
                                .map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{
                                            "&:last-child td, &:last-child th": { border: 0 },
                                        }}
                                    >
                                        <TableCell
                                            key={"1" + index}
                                            component='th'
                                            scope='row'
                                        >
                                            {row.companycode}
                                        </TableCell>
                                        <TableCell
                                            key={"2" + index}
                                            component='th'
                                            scope='row'
                                        >
                                            {row.name}
                                        </TableCell>
                                        <TableCell
                                            key={"3" + index}
                                            align='right'
                                        >
                                            <Flex
                                                key={"4" + index}
                                                direction='row'
                                            >
                                                <Button
                                                    key={"5" + index}
                                                    onClick={() => {
                                                        setSelectedPage(
                                                            "UpdateClientsTenants:" +
                                                                row.companycode,
                                                        );
                                                    }}
                                                >
                                                    Tenants
                                                </Button>
                                                <Button
                                                    key={"6" + index}
                                                    onClick={() => {
                                                        setSelectedPage(row.companycode);
                                                    }}
                                                >
                                                    Update
                                                </Button>
                                                <Button
                                                    key={"7" + index}
                                                    onClick={() => {
                                                        setClientName(row.companycode);
                                                        handleConfirmationOpen();
                                                    }}
                                                >
                                                    Delete
                                                </Button>
                                            </Flex>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        );
    }

    function ConfirmationBox() {
        return (
            <Dialog
                open={confirmationOpen}
                onClose={handleConfirmationClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>{"Delete?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        {"Are you sure you want to delete this client/tenant relationship?"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleConfirmationClose}
                        autoFocus
                    >
                        No
                    </Button>
                    <Button onClick={handleConfirm}>Yes</Button>
                </DialogActions>
            </Dialog>
        );
    }
    const handleConfirmationOpen = () => {
        setConfirmationOpen(true);
    };
    const handleConfirmationClose = () => {
        setConfirmationOpen(false);
    };
    async function handleConfirm() {
        setConfirmationOpen(false);
        var ret = await DeleteClient(clientName);
        if (ret) {
            console.log("Return from delete client: ", ret);
            alert("Client " + clientName + " deleted");
        } else console.log("Unknown error when trying to delete client: ");
    }

    function onSaveClient(): void {
        if (!clientName) {
            alert("Could not get client #.  Please try again.");
            return;
        }
        try {
            alert("This feature needs to be implemented. Client " + clientName + " will be added");
            //InsertTenantUserRelation(tenantName, props?.idProp);
        } catch (err) {
            alert(err);
            return;
        }
        alert("Client " + clientName + " added");
        setAddRelationOpen(false);
        props.onSuccess();
    }

    function onClientClose(): void {
        setAddRelationOpen(false);
    }

    function AddRelationshipBox() {
        return (
            <Dialog
                open={addRelationOpen}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <div>
                    <FormControl sx={{ m: 1, minWidth: 300 }}>
                        <TextField
                            id='outlined-basic'
                            label='Client #'
                            value={clientName}
                            onChange={(e) => setClientName((e.target as HTMLInputElement).value)}
                        />
                        <br />
                        <br />
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={6}
                            >
                                <Button
                                    onClick={() => {
                                        onClientClose();
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                            >
                                <Button
                                    onClick={() => {
                                        onSaveClient();
                                    }}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </FormControl>
                </div>
            </Dialog>
        );
    }

    React.useEffect(() => {
        async function getData() {
            var data = await SelectAllClients();
            console.log("Got clients: ", data);

            if (!data?.data?.listClients?.items) return;

            setClients(data.data.listClients.items);
        }

        getData();
    }, []);

    function showPage() {
        if (selectedPage.startsWith("UpdateClientsTenants:")) {
            const id = selectedPage.replace("UpdateClientsTenants:", "");

            return (
                <div>
                    <h3>{id}: Linked Tenants</h3>
                    {/*<UpdateClientsTenants
                        idProp={id}
                        onSuccess={() => {
                            setSelectedPage("default");
                        }}
                    />*/}
                </div>
            );
        }

        switch (selectedPage) {
            case "default":
                return (
                    <>
                        <UsersTable />
                        <br />
                        <br />
                        <Button
                            children='Go Back'
                            type='reset'
                            onClick={(event) => {
                                event.preventDefault();
                                props.onSuccess();
                            }}
                            {...getOverrideProps(null, "ClearButton")}
                        ></Button>
                    </>
                );
            default:
                return (
                    <div>
                        <h3>{selectedPage}</h3>
                        <ClientsUpdateForm
                            companycode={selectedPage}
                            onSuccess={() => {
                                setSelectedPage("default");
                            }}
                            onCancel={() => {
                                setSelectedPage("default");
                            }}
                        />
                    </div>
                );
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            {ConfirmationBox()}
            {AddRelationshipBox()}
            {showPage()}
        </Box>
    );
}
