import React, { useState } from "react";
import AccountMenu from "../components/AccountMenu";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import navItems from "./navItems";
import CustomBox from "../theme/components/Box";
import { Box, Divider, Stack } from "@mui/material";
import { useIsMobile } from "helpers/CustomHooks";

type AccountLayoutProps = {
    children: React.ReactNode;
    signOut?: any;
    numberOfCartItems?: number;
    activeButton?: string;
};

const AccountLayout = ({ children, signOut, numberOfCartItems, activeButton }: AccountLayoutProps) => {
    const [isActive, setIsActive] = useState<string | null>(activeButton || null);

    const isMobile = useIsMobile();

    const handleButtonClick = (path: string) => {
        setIsActive(path);
    };

    return (
        <CustomBox>
            <NavBar
                navItems={navItems}
                signOut={signOut}
                numberOfCartItems={numberOfCartItems}
            />
            <Stack
                direction={isMobile ? "column" : "row"}
                spacing={1}
            >
                <Box sx={{ maxWidth: "250px", width: "100%" }}>
                    <AccountMenu
                        onButtonClick={handleButtonClick}
                        isActive={isActive}
                    />
                </Box>
                {!isMobile && (
                    <Box>
                        <Divider
                            orientation='vertical'
                            sx={{
                                height: "100%",
                                mx: 3,
                                borderWidth: 1,
                                borderColor: "#dcdcdc",
                            }}
                        />
                    </Box>
                )}
                <Box sx={{ width: isMobile ? "100%" : "800px" }}>{children}</Box>
            </Stack>
            {isMobile && <Box sx={{ height: "150px" }} />}
            <Footer />
        </CustomBox>
    );
};

export default AccountLayout;
