import {
    AssessmentOutlined,
    AttachMoneyOutlined,
    GridViewOutlined,
    HandymanOutlined,
    ShoppingCartOutlined,
    ViewInArOutlined,
} from "@mui/icons-material";

function getSettingByName(name: string) {
    return "1";
}

const navItems = [
    {
        label: "Dashboard",
        path: "/dashboardpage",
        permissionname: "showdashboardpage",
        visible: false,
        Icon: GridViewOutlined,
    },
    {
        label: "Products",
        path: "/products",
        permissionname: "showmainstore",
        visible: false,
        Icon: ViewInArOutlined,
    },
    {
        label: "Service Requests",
        path: "/servicerequests",
        permissionname: "showservicerequests",
        visible: false,
        Icon: HandymanOutlined,
    },
    {
        label: "Payments",
        path: "/payments",
        permissionname: "showviewopeninvoices",
        visible: false,
        Icon: AttachMoneyOutlined,
    },
    {
        label: "Reports",
        path: "/reports",
        permissionname: "showreports",
        visible: false,
        Icon: AssessmentOutlined,
    },
    {
        label: "Cart",
        path: "/cart",
        permissionname: "showmainstore",
        visible: false,
        Icon: ShoppingCartOutlined,
    },
];

export default navItems;
