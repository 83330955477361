import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider, CssBaseline } from "@mui/material";
import theme from "theme/theme";
import Authenticate from "helpers/Authenticate";
import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import config from "./amplifyconfiguration.json";
import CustomBox from "theme/components/Box";
import "./index.css";

Amplify.configure(config);

// Our types for the site data
export interface Props {
    name: String;
    description: String;
    subdomain: String;
    customDomain: String;
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline>
                <Authenticator.Provider>
                    <Authenticate />
                </Authenticator.Provider>
            </CssBaseline>
        </ThemeProvider>
    </React.StrictMode>,
);
