import { Radio, Stack, styled } from "@mui/material";
import React from "react";

type Props = {
    poNumber: string;
    deliveryInstructions: string;
    paymentType: string;
    ccProfileID: string;
    ccustno: string;
    price: string;
    tax: string;
    total: string;
    totalCases: string;
    numOfItems: number;
};

const StyledRadio = styled(Radio)({
    "&.MuiRadio-root": {
        color: "#58B55D;",
        padding: "1px",
    },
    "&.MuiRadio-colorSecondary": {
        "&.Mui-checked": {
            color: "#58B55D;",
            padding: "1px",
        },
    },
});

const CheckoutInfo: React.FC<Props> = ({
    poNumber,
    price,
    tax,
    total,
    totalCases,
    numOfItems,
    deliveryInstructions,
    paymentType,
    ccProfileID,
    ccustno,
}) => {
    return (
        <Stack
            spacing={2}
            direction='column'
            sx={{ m: "20px" }}
        >
            <table>
                <tbody>
                    <tr>
                        <td>
                            <div className='checkout_info_label'>PO Number</div>
                        </td>
                        <td>
                            <div className='checkout_info_value'>{poNumber}</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className='checkout_info_label'>
                                Price {"(" + numOfItems + " items )"}
                            </div>
                        </td>
                        <td>
                            <div className='checkout_info_value_bold'>${price}</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className='checkout_info_label'>Tax</div>
                        </td>
                        <td>
                            <div className='checkout_info_value'>${tax}</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className='checkout_info_label'>Order Total</div>
                        </td>
                        <td>
                            <div className='checkout_info_value_bold'>${total}</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div className='checkout_info_label'>Total Cases</div>
                        </td>
                        <td>
                            <div className='checkout_info_value_bold'>{totalCases}</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </Stack>
    );
};

export default CheckoutInfo;
