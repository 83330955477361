import { Button } from "@mui/material";
import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

export type Props = {
    message: string;
    onGoBack: () => void;
};

const SuccessDisplay: React.FC<Props> = (props) => {
    return (
        <Card>
            <CardContent>
                <Alert
                    variant='filled'
                    severity='success'
                >
                    {props.message}
                </Alert>
                <br />
                <br />
                <Button
                    variant='contained'
                    onClick={() => {
                        props.onGoBack();
                    }}
                >
                    Close
                </Button>
            </CardContent>
        </Card>
    );
};

export default SuccessDisplay;
