import { Flex, Loader, Theme, ThemeProvider } from "@aws-amplify/ui-react";
import { Box, styled } from "@mui/material";
import React from "react";

const SpinnerContainer = styled("div")(({ theme }) => ({
    margin: "auto",
    width: "300px",
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
}));

const theme: Theme = {
    name: "loader-theme",
    tokens: {
        components: {
            loader: {
                strokeEmpty: { value: "{colors.neutral.20}" },
                strokeFilled: { value: "{colors.green.80}" },

                // sizes
                large: {
                    width: { value: "{fontSizes.xxxl}" },
                    height: { value: "{fontSizes.xxxl}" },
                },

                // linear loader
                linear: {
                    width: { value: "50%" },
                    strokeWidth: { value: "{fontSizes.xxs}" },
                    strokeFilled: { value: "{colors.secondary.80}" },
                    strokeEmpty: { value: "{colors.neutral.20}" },
                    animationDuration: { value: "2s" },
                },
            },
        },
    },
};

const LoaderTheme = () => (
    <ThemeProvider
        theme={theme}
        colorMode='light'
    >
        <Flex direction='column'>
            <Loader size='large' />
        </Flex>
    </ThemeProvider>
);

export interface LoadingSpinnerProps {
    message: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ message }) => {
    return (
        <>
            <Box sx={{ width: "100vw" }} />
            {message && (
                <SpinnerContainer>
                    <h3>{message}</h3>
                </SpinnerContainer>
            )}
            <SpinnerContainer>
                <LoaderTheme />
            </SpinnerContainer>
        </>
    );
};

export default LoadingSpinner;
