import { generateClient } from "aws-amplify/api";
import {
    createTenants,
    createTenantsUsers,
    createUsers,
    deleteClients,
    deleteHubs,
    deleteTenantsUsers,
} from "../graphql/mutations";
import {
    hubByTenantId,
    listClients,
    listHubs,
    listTenants,
    listTenantsUsers,
    listUsers,
    tenantBySubdomain,
    tenantsUsersByUsersId,
    userByUsername,
} from "../graphql/queries";

const client = generateClient();

export async function InsertTenant(
    tenantName: string,
    description: string,
    url: string,
    authuser: string,
    authkey: string,
    address1: string,
    address2: string,
    usesHubs: boolean,
    city: string,
    state: string,
    zip: string,
    phone: string,
    email: string,
) {
    const result = await client.graphql({
        query: createTenants,
        variables: {
            input: {
                id: tenantName.trim().toLowerCase(),
                subdomain: tenantName.trim().toLowerCase(),
                name: description,
                url: url,
                usesHubs: usesHubs,
                uniqueid: authkey,
                createdby: authuser,
                address1: address1,
                address2: address2,
                city: city,
                state: state,
                zip: zip,
                phone: phone,
                email: email,
            },
        },
    });
    return result;
}

export async function SelectTenant(tenantName: string) {
    const result = await client.graphql({
        query: tenantBySubdomain,
        variables: {
            subdomain: tenantName.trim().toLowerCase(),
        },
    });
    return result;
}

export async function SelectAllTenants() {
    const result = await client.graphql({
        query: listTenants,
        variables: {},
    });
    return result;
}

export async function SelectAllUsers() {
    const result = await client.graphql({
        query: listUsers,
        variables: {},
    });
    return result;
}

export async function SelectUser(username: string) {
    const result = await client.graphql({
        query: userByUsername,
        variables: {
            username: username.trim().toLowerCase(),
        },
    });
    return result;
}

export async function SelectAllTenantsUsers() {
    const result = await client.graphql({
        query: listTenantsUsers,
        variables: {},
    });
    return result;
}

export type UserWithTenants = { userid: string; tenants: string[] };

export async function SelectUsersWithTenants() {
    const users: UserWithTenants[] = [];
    const result = await client.graphql({
        query: listTenantsUsers,
        variables: {},
    });

    if (result?.data?.listTenantsUsers?.items) {
        for (let i = 0; i < result.data.listTenantsUsers.items.length; i++) {
            const item = result.data.listTenantsUsers.items[i];
            if (users.find((u) => u.userid === item.usersId)) {
                users.find((u) => u.userid === item.usersId)?.tenants.push(item.tenantsId);
            } else {
                users.push({ userid: item.usersId, tenants: [item.tenantsId] });
            }
        }
    }

    return users;
}

export async function SelectUserTenants(username: string) {
    const result = await client.graphql({
        query: tenantsUsersByUsersId,
        variables: {
            usersId: username.trim().toLowerCase(),
        },
    });
    return result;
}

export async function InsertUser(tenantName: string, username: string, firstname: string, lastname: string) {
    console.log("GraphQl: InsertUser - " + username + " to tenant " + tenantName);
    const result = await client.graphql({
        query: createUsers,
        variables: {
            input: {
                id: username.trim().toLowerCase(),
                username: username.trim().toLowerCase(),
                firstname: firstname,
                lastname: lastname,
            },
        },
    });
    console.log("GraphQl: InsertUser: Result 1: ", result);
    if (result.errors && result.errors.length > 0) {
        return result;
    }
    console.log("GraphQl: InsertUser: Inserting Link record: ");
    const result2 = await client.graphql({
        query: createTenantsUsers,
        variables: {
            input: {
                id: tenantName.trim().toLowerCase() + "_" + username.trim().toLowerCase(),
                usersId: username.trim().toLowerCase(),
                tenantsId: tenantName.trim().toLowerCase(),
            },
        },
    });
    console.log("GraphQl: InsertUser: Result 2: ", result2);
    return result;
}

export async function InsertTenantUserRelation(tenantName: string, username: string) {
    console.log("GraphQl: InsertUser: Inserting Link record: ");
    const result2 = await client.graphql({
        query: createTenantsUsers,
        variables: {
            input: {
                id: tenantName.trim().toLowerCase() + "_" + username.trim().toLowerCase(),
                usersId: username.trim().toLowerCase(),
                tenantsId: tenantName.trim().toLowerCase(),
            },
        },
    });
    console.log("GraphQl: InsertTenantUserRelation: Result: ", result2);
}

export async function DeleteTenantUserRelationship(id: string) {
    const result = await client.graphql({
        query: deleteTenantsUsers,
        variables: {
            input: {
                id: id,
            },
        },
    });
    return result;
}

export async function SelectAllClients() {
    const result = await client.graphql({
        query: listClients,
        variables: {},
    });
    return result;
}

export async function SelectClient(companycode: string) {
    const result = await client.graphql({
        query: listClients,
        variables: {
            companycode: companycode,
        },
    });
    return result;
}

export async function DeleteClient(companycode: string) {
    const result = await client.graphql({
        query: deleteClients,
        variables: {
            input: {
                companycode: companycode,
            },
        },
    });
    return result;
}

export async function SelectAllHubs() {
    const result = await client.graphql({
        query: listHubs,
        variables: {},
    });
    return result;
}

export async function DeleteHub(hubid: string) {
    const result = await client.graphql({
        query: deleteHubs,
        variables: {
            input: {
                id: hubid,
            },
        },
    });
    return result;
}

/*export async function SelectClientsTenants(companycode: string) {
    const result = await client.graphql({
        query: tenantsClientsByClientsCompanycode,
        variables: {
            clientsCompanycode: companycode,
        },
    });
    return result;
}

export async function InsertTenantClientRelation(tenantName: string, companycode: string) {
    console.log("GraphQl: InsertUser: Inserting Link record: ");
    const result2 = await client.graphql({
        query: createTenantsClients,
        variables: {
            input: {
                id: tenantName.trim().toLowerCase() + "_" + companycode.trim().toUpperCase(),
                clientsCompanycode: companycode.trim().toUpperCase(),
                tenantsId: tenantName.trim().toLowerCase(),
            },
        },
    });
    console.log("GraphQl: InsertTenantUserRelation: Result: ", result2);
}

export async function DeleteTenantClientRelationship(id: string) {
    const result = await client.graphql({
        query: deleteTenantsClients,
        variables: {
            input: {
                id: id,
            },
        },
    });
    return result;
}
*/

export async function SelectHubsByTenantID(tenantID: string) {
    const result = await client.graphql({
        query: hubByTenantId,
        variables: {
            tenantid: tenantID,
        },
    });
    return result;
}
