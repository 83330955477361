/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import UploadFile from "../helpers/S3ImageStorage";
import {
    Autocomplete,
    Badge,
    Button,
    Divider,
    Flex,
    Grid,
    Icon,
    ScrollView,
    Text,
    TextField,
    useTheme,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getHubs, listTenants } from "../graphql/queries";
import { updateHubs } from "../graphql/mutations";
const client = generateClient();
function ArrayField({
    items = [],
    onChange,
    label,
    inputFieldRef,
    children,
    hasError,
    setFieldValue,
    currentFieldValue,
    defaultFieldValue,
    lengthLimit,
    getBadgeText,
    runValidationTasks,
    errorMessage,
}) {
    const labelElement = <Text>{label}</Text>;
    const {
        tokens: {
            components: {
                fieldmessages: { error: errorStyles },
            },
        },
    } = useTheme();
    const [selectedBadgeIndex, setSelectedBadgeIndex] = React.useState();
    const [isEditing, setIsEditing] = React.useState();
    React.useEffect(() => {
        if (isEditing) {
            inputFieldRef?.current?.focus();
        }
    }, [isEditing]);
    const removeItem = async (removeIndex) => {
        const newItems = items.filter((value, index) => index !== removeIndex);
        await onChange(newItems);
        setSelectedBadgeIndex(undefined);
    };
    const addItem = async () => {
        const { hasError } = runValidationTasks();
        if (
            currentFieldValue !== undefined &&
            currentFieldValue !== null &&
            currentFieldValue !== "" &&
            !hasError
        ) {
            const newItems = [...items];
            if (selectedBadgeIndex !== undefined) {
                newItems[selectedBadgeIndex] = currentFieldValue;
                setSelectedBadgeIndex(undefined);
            } else {
                newItems.push(currentFieldValue);
            }
            await onChange(newItems);
            setIsEditing(false);
        }
    };
    const arraySection = (
        <React.Fragment>
            {!!items?.length && (
                <ScrollView
                    height='inherit'
                    width='inherit'
                    maxHeight={"7rem"}
                >
                    {items.map((value, index) => {
                        return (
                            <Badge
                                key={index}
                                style={{
                                    cursor: "pointer",
                                    alignItems: "center",
                                    marginRight: 3,
                                    marginTop: 3,
                                    backgroundColor: index === selectedBadgeIndex ? "#B8CEF9" : "",
                                }}
                                onClick={() => {
                                    setSelectedBadgeIndex(index);
                                    setFieldValue(items[index]);
                                    setIsEditing(true);
                                }}
                            >
                                {getBadgeText ? getBadgeText(value) : value.toString()}
                                <Icon
                                    style={{
                                        cursor: "pointer",
                                        paddingLeft: 3,
                                        width: 20,
                                        height: 20,
                                    }}
                                    viewBox={{ width: 20, height: 20 }}
                                    paths={[
                                        {
                                            d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
                                            stroke: "black",
                                        },
                                    ]}
                                    ariaLabel='button'
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        removeItem(index);
                                    }}
                                />
                            </Badge>
                        );
                    })}
                </ScrollView>
            )}
            <Divider
                orientation='horizontal'
                marginTop={5}
            />
        </React.Fragment>
    );
    if (lengthLimit !== undefined && items.length >= lengthLimit && !isEditing) {
        return (
            <React.Fragment>
                {labelElement}
                {arraySection}
            </React.Fragment>
        );
    }
    return (
        <React.Fragment>
            {labelElement}
            {isEditing && children}
            {!isEditing ?
                <>
                    <Button
                        onClick={() => {
                            setIsEditing(true);
                        }}
                    >
                        Add item
                    </Button>
                    {errorMessage && hasError && (
                        <Text
                            color={errorStyles.color}
                            fontSize={errorStyles.fontSize}
                        >
                            {errorMessage}
                        </Text>
                    )}
                </>
            :   <Flex justifyContent='flex-end'>
                    {(currentFieldValue || isEditing) && (
                        <Button
                            children='Cancel'
                            type='button'
                            size='small'
                            onClick={() => {
                                setFieldValue(defaultFieldValue);
                                setIsEditing(false);
                                setSelectedBadgeIndex(undefined);
                            }}
                        ></Button>
                    )}
                    <Button
                        size='small'
                        variation='link'
                        onClick={addItem}
                    >
                        {selectedBadgeIndex !== undefined ? "Save" : "Add"}
                    </Button>
                </Flex>
            }
            {arraySection}
        </React.Fragment>
    );
}
export default function HubsUpdateForm(props) {
    const {
        id: idProp,
        hubs: hubsModelProp,
        onSuccess,
        onError,
        onSubmit,
        onCancel,
        onValidate,
        onChange,
        overrides,
        ...rest
    } = props;
    const initialValues = {
        chubno: "",
        description: "",
        logo: "",
        companyname: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        email: "",
        tenant: undefined,
    };
    const [chubno, setChubno] = React.useState(initialValues.chubno);
    const [description, setDescription] = React.useState(initialValues.description);
    const [logo, setLogo] = React.useState(initialValues.logo);
    const [companyname, setCompanyname] = React.useState(initialValues.companyname);
    const [address1, setAddress1] = React.useState(initialValues.address1);
    const [address2, setAddress2] = React.useState(initialValues.address2);
    const [city, setCity] = React.useState(initialValues.city);
    const [state, setState] = React.useState(initialValues.state);
    const [zip, setZip] = React.useState(initialValues.zip);
    const [phone, setPhone] = React.useState(initialValues.phone);
    const [email, setEmail] = React.useState(initialValues.email);
    const [tenant, setTenant] = React.useState(initialValues.tenant);
    const [tenantLoading, setTenantLoading] = React.useState(false);
    const [tenantRecords, setTenantRecords] = React.useState([]);
    const autocompleteLength = 10;
    const [errors, setErrors] = React.useState({});
    const resetStateValues = () => {
        const cleanValues =
            hubsRecord ? { ...initialValues, ...hubsRecord, tenant } : initialValues;
        setChubno(cleanValues.chubno);
        setDescription(cleanValues.description);
        setLogo(cleanValues.logo);
        setCompanyname(cleanValues.companyname);
        setAddress1(cleanValues.address1);
        setAddress2(cleanValues.address2);
        setCity(cleanValues.city);
        setState(cleanValues.state);
        setZip(cleanValues.zip);
        setPhone(cleanValues.phone);
        setEmail(cleanValues.email);
        setTenant(cleanValues.tenant);
        setCurrentTenantValue(undefined);
        setCurrentTenantDisplayValue("");
        setErrors({});
    };
    const [hubsRecord, setHubsRecord] = React.useState(hubsModelProp);
    React.useEffect(() => {
        const queryData = async () => {
            const record =
                idProp ?
                    (
                        await client.graphql({
                            query: getHubs.replaceAll("__typename", ""),
                            variables: { id: idProp },
                        })
                    )?.data?.getHubs
                :   hubsModelProp;
            const tenantRecord = record ? await record.tenant : undefined;
            setTenant(tenantRecord);
            setHubsRecord(record);
        };
        queryData();
    }, [idProp, hubsModelProp]);

    const [openFileDialog, setOpenFileDialog] = React.useState(false);

    function setLogoURL(url) {
        setLogo(url);
    }

    React.useEffect(resetStateValues, [hubsRecord, tenant]);
    const [currentTenantDisplayValue, setCurrentTenantDisplayValue] = React.useState("");
    const [currentTenantValue, setCurrentTenantValue] = React.useState(undefined);
    const tenantRef = React.createRef();
    const getIDValue = {
        tenant: (r) => JSON.stringify({ id: r?.id }),
    };
    const tenantIdSet = new Set(
        Array.isArray(tenant) ?
            tenant.map((r) => getIDValue.tenant?.(r))
        :   getIDValue.tenant?.(tenant),
    );
    const getDisplayValue = {
        tenant: (r) => `${r?.subdomain ? r?.subdomain + " - " : ""}${r?.id}`,
    };
    const validations = {
        chubno: [{ type: "Required" }],
        description: [{ type: "Required" }],
        logo: [],
        companyname: [],
        address1: [],
        address2: [],
        city: [],
        state: [],
        zip: [],
        phone: [],
        email: [],
        tenant: [],
    };
    const runValidationTasks = async (fieldName, currentValue, getDisplayValue) => {
        const value =
            currentValue && getDisplayValue ? getDisplayValue(currentValue) : currentValue;
        let validationResponse = validateField(value, validations[fieldName]);
        const customValidator = fetchByPath(onValidate, fieldName);
        if (customValidator) {
            validationResponse = await customValidator(value, validationResponse);
        }
        setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
        return validationResponse;
    };
    const fetchTenantRecords = async (value) => {
        setTenantLoading(true);
        const newOptions = [];
        let newNext = "";
        while (newOptions.length < autocompleteLength && newNext != null) {
            const variables = {
                limit: autocompleteLength * 5,
                filter: {
                    or: [{ subdomain: { contains: value } }, { id: { contains: value } }],
                },
            };
            if (newNext) {
                variables["nextToken"] = newNext;
            }
            const result = (
                await client.graphql({
                    query: listTenants.replaceAll("__typename", ""),
                    variables,
                })
            )?.data?.listTenants?.items;
            var loaded = result.filter((item) => !tenantIdSet.has(getIDValue.tenant?.(item)));
            newOptions.push(...loaded);
            newNext = result.nextToken;
        }
        setTenantRecords(newOptions.slice(0, autocompleteLength));
        setTenantLoading(false);
    };
    React.useEffect(() => {
        fetchTenantRecords("");
    }, []);
    return (
        <Grid
            as='form'
            rowGap='15px'
            columnGap='15px'
            padding='20px'
            onSubmit={async (event) => {
                event.preventDefault();
                let modelFields = {
                    chubno,
                    description,
                    logo: logo ?? null,
                    companyname: companyname ?? null,
                    address1: address1 ?? null,
                    address2: address2 ?? null,
                    city: city ?? null,
                    state: state ?? null,
                    zip: zip ?? null,
                    phone: phone ?? null,
                    email: email ?? null,
                    tenant: tenant ?? null,
                };
                const validationResponses = await Promise.all(
                    Object.keys(validations).reduce((promises, fieldName) => {
                        if (Array.isArray(modelFields[fieldName])) {
                            promises.push(
                                ...modelFields[fieldName].map((item) =>
                                    runValidationTasks(fieldName, item, getDisplayValue[fieldName]),
                                ),
                            );
                            return promises;
                        }
                        promises.push(
                            runValidationTasks(
                                fieldName,
                                modelFields[fieldName],
                                getDisplayValue[fieldName],
                            ),
                        );
                        return promises;
                    }, []),
                );
                if (validationResponses.some((r) => r.hasError)) {
                    return;
                }
                if (onSubmit) {
                    modelFields = onSubmit(modelFields);
                }
                try {
                    Object.entries(modelFields).forEach(([key, value]) => {
                        if (typeof value === "string" && value === "") {
                            modelFields[key] = null;
                        }
                    });
                    const modelFieldsToSave = {
                        chubno: modelFields.chubno,
                        description: modelFields.description,
                        logo: modelFields.logo ?? null,
                        companyname: modelFields.companyname ?? null,
                        address1: modelFields.address1 ?? null,
                        address2: modelFields.address2 ?? null,
                        city: modelFields.city ?? null,
                        state: modelFields.state ?? null,
                        zip: modelFields.zip ?? null,
                        phone: modelFields.phone ?? null,
                        email: modelFields.email ?? null,
                        tenantid: modelFields?.tenant?.id ?? null,
                    };
                    await client.graphql({
                        query: updateHubs.replaceAll("__typename", ""),
                        variables: {
                            input: {
                                id: hubsRecord.id,
                                ...modelFieldsToSave,
                            },
                        },
                    });
                    if (onSuccess) {
                        onSuccess(modelFields);
                    }
                } catch (err) {
                    if (onError) {
                        const messages = err.errors.map((e) => e.message).join("\n");
                        onError(modelFields, messages);
                    }
                }
            }}
            {...getOverrideProps(overrides, "HubsUpdateForm")}
            {...rest}
        >
            <TextField
                label='Chubno'
                isRequired={true}
                isReadOnly={false}
                value={chubno}
                onChange={(e) => {
                    let { value } = e.target;
                    if (onChange) {
                        const modelFields = {
                            chubno: value,
                            description,
                            logo,
                            companyname,
                            address1,
                            address2,
                            city,
                            state,
                            zip,
                            phone,
                            email,
                            tenant,
                        };
                        const result = onChange(modelFields);
                        value = result?.chubno ?? value;
                    }
                    if (errors.chubno?.hasError) {
                        runValidationTasks("chubno", value);
                    }
                    setChubno(value);
                }}
                onBlur={() => runValidationTasks("chubno", chubno)}
                errorMessage={errors.chubno?.errorMessage}
                hasError={errors.chubno?.hasError}
                {...getOverrideProps(overrides, "chubno")}
            ></TextField>
            <TextField
                label='Description'
                isRequired={true}
                isReadOnly={false}
                value={description}
                onChange={(e) => {
                    let { value } = e.target;
                    if (onChange) {
                        const modelFields = {
                            chubno,
                            description: value,
                            logo,
                            companyname,
                            address1,
                            address2,
                            city,
                            state,
                            zip,
                            phone,
                            email,
                            tenant,
                        };
                        const result = onChange(modelFields);
                        value = result?.description ?? value;
                    }
                    if (errors.description?.hasError) {
                        runValidationTasks("description", value);
                    }
                    setDescription(value);
                }}
                onBlur={() => runValidationTasks("description", description)}
                errorMessage={errors.description?.errorMessage}
                hasError={errors.description?.hasError}
                {...getOverrideProps(overrides, "description")}
            ></TextField>
            <TextField
                label='Logo'
                isRequired={false}
                isReadOnly={false}
                value={logo}
                onChange={(e) => {
                    let { value } = e.target;
                    if (onChange) {
                        const modelFields = {
                            chubno,
                            description,
                            logo: value,
                            companyname,
                            address1,
                            address2,
                            city,
                            state,
                            zip,
                            phone,
                            email,
                            tenant,
                        };
                        const result = onChange(modelFields);
                        value = result?.logo ?? value;
                    }
                    if (errors.logo?.hasError) {
                        runValidationTasks("logo", value);
                    }
                    setLogo(value);
                }}
                onBlur={() => runValidationTasks("logo", logo)}
                errorMessage={errors.logo?.errorMessage}
                hasError={errors.logo?.hasError}
                {...getOverrideProps(overrides, "logo")}
            ></TextField>
            <Button
                onClick={() => {
                    setOpenFileDialog(true);
                }}
            >
                Upload Logo
            </Button>
            <UploadFile
                setURL={setLogoURL}
                open={openFileDialog}
                setOpen={setOpenFileDialog}
                tenantId={tenant ? tenant.subdomain : ""}
                directory='logos'
            />
            <TextField
                label='Company Name'
                isRequired={false}
                isReadOnly={false}
                value={companyname}
                onChange={(e) => {
                    let { value } = e.target;
                    if (onChange) {
                        const modelFields = {
                            chubno,
                            description,
                            logo,
                            companyname: value,
                            address1,
                            address2,
                            city,
                            state,
                            zip,
                            phone,
                            email,
                            tenant,
                        };
                        const result = onChange(modelFields);
                        value = result?.companyname ?? value;
                    }
                    if (errors.companyname?.hasError) {
                        runValidationTasks("companyname", value);
                    }
                    setCompanyname(value);
                }}
                onBlur={() => runValidationTasks("companyname", companyname)}
                errorMessage={errors.companyname?.errorMessage}
                hasError={errors.companyname?.hasError}
                {...getOverrideProps(overrides, "companyname")}
            ></TextField>
            <TextField
                label='Address1'
                isRequired={false}
                isReadOnly={false}
                value={address1}
                onChange={(e) => {
                    let { value } = e.target;
                    if (onChange) {
                        const modelFields = {
                            chubno,
                            description,
                            logo,
                            companyname,
                            address1: value,
                            address2,
                            city,
                            state,
                            zip,
                            phone,
                            email,
                            tenant,
                        };
                        const result = onChange(modelFields);
                        value = result?.address1 ?? value;
                    }
                    if (errors.address1?.hasError) {
                        runValidationTasks("address1", value);
                    }
                    setAddress1(value);
                }}
                onBlur={() => runValidationTasks("address1", address1)}
                errorMessage={errors.address1?.errorMessage}
                hasError={errors.address1?.hasError}
                {...getOverrideProps(overrides, "address1")}
            ></TextField>
            <TextField
                label='Address2'
                isRequired={false}
                isReadOnly={false}
                value={address2}
                onChange={(e) => {
                    let { value } = e.target;
                    if (onChange) {
                        const modelFields = {
                            chubno,
                            description,
                            logo,
                            companyname,
                            address1,
                            address2: value,
                            city,
                            state,
                            zip,
                            phone,
                            email,
                            tenant,
                        };
                        const result = onChange(modelFields);
                        value = result?.address2 ?? value;
                    }
                    if (errors.address2?.hasError) {
                        runValidationTasks("address2", value);
                    }
                    setAddress2(value);
                }}
                onBlur={() => runValidationTasks("address2", address2)}
                errorMessage={errors.address2?.errorMessage}
                hasError={errors.address2?.hasError}
                {...getOverrideProps(overrides, "address2")}
            ></TextField>
            <TextField
                label='City'
                isRequired={false}
                isReadOnly={false}
                value={city}
                onChange={(e) => {
                    let { value } = e.target;
                    if (onChange) {
                        const modelFields = {
                            chubno,
                            description,
                            logo,
                            companyname,
                            address1,
                            address2,
                            city: value,
                            state,
                            zip,
                            phone,
                            email,
                            tenant,
                        };
                        const result = onChange(modelFields);
                        value = result?.city ?? value;
                    }
                    if (errors.city?.hasError) {
                        runValidationTasks("city", value);
                    }
                    setCity(value);
                }}
                onBlur={() => runValidationTasks("city", city)}
                errorMessage={errors.city?.errorMessage}
                hasError={errors.city?.hasError}
                {...getOverrideProps(overrides, "city")}
            ></TextField>
            <TextField
                label='State'
                isRequired={false}
                isReadOnly={false}
                value={state}
                onChange={(e) => {
                    let { value } = e.target;
                    if (onChange) {
                        const modelFields = {
                            chubno,
                            description,
                            logo,
                            companyname,
                            address1,
                            address2,
                            city,
                            state: value,
                            zip,
                            phone,
                            email,
                            tenant,
                        };
                        const result = onChange(modelFields);
                        value = result?.state ?? value;
                    }
                    if (errors.state?.hasError) {
                        runValidationTasks("state", value);
                    }
                    setState(value);
                }}
                onBlur={() => runValidationTasks("state", state)}
                errorMessage={errors.state?.errorMessage}
                hasError={errors.state?.hasError}
                {...getOverrideProps(overrides, "state")}
            ></TextField>
            <TextField
                label='Zip'
                isRequired={false}
                isReadOnly={false}
                value={zip}
                onChange={(e) => {
                    let { value } = e.target;
                    if (onChange) {
                        const modelFields = {
                            chubno,
                            description,
                            logo,
                            companyname,
                            address1,
                            address2,
                            city,
                            state,
                            zip: value,
                            phone,
                            email,
                            tenant,
                        };
                        const result = onChange(modelFields);
                        value = result?.zip ?? value;
                    }
                    if (errors.zip?.hasError) {
                        runValidationTasks("zip", value);
                    }
                    setZip(value);
                }}
                onBlur={() => runValidationTasks("zip", zip)}
                errorMessage={errors.zip?.errorMessage}
                hasError={errors.zip?.hasError}
                {...getOverrideProps(overrides, "zip")}
            ></TextField>
            <TextField
                label='Phone'
                isRequired={false}
                isReadOnly={false}
                value={phone}
                onChange={(e) => {
                    let { value } = e.target;
                    if (onChange) {
                        const modelFields = {
                            chubno,
                            description,
                            logo,
                            companyname,
                            address1,
                            address2,
                            city,
                            state,
                            zip,
                            phone: value,
                            email,
                            tenant,
                        };
                        const result = onChange(modelFields);
                        value = result?.phone ?? value;
                    }
                    if (errors.phone?.hasError) {
                        runValidationTasks("phone", value);
                    }
                    setPhone(value);
                }}
                onBlur={() => runValidationTasks("phone", phone)}
                errorMessage={errors.phone?.errorMessage}
                hasError={errors.phone?.hasError}
                {...getOverrideProps(overrides, "phone")}
            ></TextField>
            <TextField
                label='Email'
                isRequired={false}
                isReadOnly={false}
                value={email}
                onChange={(e) => {
                    let { value } = e.target;
                    if (onChange) {
                        const modelFields = {
                            chubno,
                            description,
                            logo,
                            companyname,
                            address1,
                            address2,
                            city,
                            state,
                            zip,
                            phone,
                            email: value,
                            tenant,
                        };
                        const result = onChange(modelFields);
                        value = result?.email ?? value;
                    }
                    if (errors.email?.hasError) {
                        runValidationTasks("email", value);
                    }
                    setEmail(value);
                }}
                onBlur={() => runValidationTasks("email", email)}
                errorMessage={errors.email?.errorMessage}
                hasError={errors.email?.hasError}
                {...getOverrideProps(overrides, "email")}
            ></TextField>
            <ArrayField
                lengthLimit={1}
                onChange={async (items) => {
                    let value = items[0];
                    if (onChange) {
                        const modelFields = {
                            chubno,
                            description,
                            logo,
                            companyname,
                            address1,
                            address2,
                            city,
                            state,
                            zip,
                            phone,
                            email,
                            tenant: value,
                        };
                        const result = onChange(modelFields);
                        value = result?.tenant ?? value;
                    }
                    setTenant(value);
                    setCurrentTenantValue(undefined);
                    setCurrentTenantDisplayValue("");
                }}
                currentFieldValue={currentTenantValue}
                label={"Tenant"}
                items={tenant ? [tenant] : []}
                hasError={errors?.tenant?.hasError}
                runValidationTasks={async () =>
                    await runValidationTasks("tenant", currentTenantValue)
                }
                errorMessage={errors?.tenant?.errorMessage}
                getBadgeText={getDisplayValue.tenant}
                setFieldValue={(model) => {
                    setCurrentTenantDisplayValue(model ? getDisplayValue.tenant(model) : "");
                    setCurrentTenantValue(model);
                }}
                inputFieldRef={tenantRef}
                defaultFieldValue={""}
            >
                <Autocomplete
                    label='Tenant'
                    isRequired={false}
                    isReadOnly={false}
                    placeholder='Search Tenants'
                    value={currentTenantDisplayValue}
                    options={tenantRecords
                        .filter((r) => !tenantIdSet.has(getIDValue.tenant?.(r)))
                        .map((r) => ({
                            id: getIDValue.tenant?.(r),
                            label: getDisplayValue.tenant?.(r),
                        }))}
                    isLoading={tenantLoading}
                    onSelect={({ id, label }) => {
                        setCurrentTenantValue(
                            tenantRecords.find((r) =>
                                Object.entries(JSON.parse(id)).every(
                                    ([key, value]) => r[key] === value,
                                ),
                            ),
                        );
                        setCurrentTenantDisplayValue(label);
                        runValidationTasks("tenant", label);
                    }}
                    onClear={() => {
                        setCurrentTenantDisplayValue("");
                    }}
                    defaultValue={tenant}
                    onChange={(e) => {
                        let { value } = e.target;
                        fetchTenantRecords(value);
                        if (errors.tenant?.hasError) {
                            runValidationTasks("tenant", value);
                        }
                        setCurrentTenantDisplayValue(value);
                        setCurrentTenantValue(undefined);
                    }}
                    onBlur={() => runValidationTasks("tenant", currentTenantDisplayValue)}
                    errorMessage={errors.tenant?.errorMessage}
                    hasError={errors.tenant?.hasError}
                    ref={tenantRef}
                    labelHidden={true}
                    {...getOverrideProps(overrides, "tenant")}
                ></Autocomplete>
            </ArrayField>
            <Flex
                justifyContent='space-between'
                {...getOverrideProps(overrides, "CTAFlex")}
            >
                <Button
                    children='Go Back'
                    type='reset'
                    onClick={(event) => {
                        event.preventDefault();
                        resetStateValues();
                    }}
                    isDisabled={!(idProp || hubsModelProp)}
                    {...getOverrideProps(overrides, "ResetButton")}
                ></Button>
                <Flex
                    gap='15px'
                    {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
                >
                    <Button
                        children='Cancel'
                        type='button'
                        onClick={() => {
                            onCancel && onCancel();
                        }}
                        {...getOverrideProps(overrides, "CancelButton")}
                    ></Button>
                    <Button
                        children='Submit'
                        type='submit'
                        variation='primary'
                        isDisabled={
                            !(idProp || hubsModelProp) ||
                            Object.values(errors).some((e) => e?.hasError)
                        }
                        {...getOverrideProps(overrides, "SubmitButton")}
                    ></Button>
                </Flex>
            </Flex>
        </Grid>
    );
}
