import { Box, Typography, List, Button, ListItemText } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Settings, GetSettings } from "services/GetInvoiceData";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import theme from "theme/theme";

type AccountMenuProps = {
    onButtonClick: (path: string) => void;
    isActive: string | null;
};

function AccountMenu({ onButtonClick, isActive }: AccountMenuProps) {
    const [settings, setSettings] = React.useState([] as Settings[]);
    const [permissionMenuItems, setPermissionMenuItems] = React.useState([] as any[]);

    const navigate = useNavigate();

    React.useEffect(() => {
        const getSettings = async () => {
            const settings = await GetSettings();
            setSettings(settings);
        };
        getSettings();
    }, []);

    const menuItems = [
        /*
        { text: "My Account", path: "/myaccount",  },
        { text: "My Orders", path: "/myorders", permissionname: "My Orders" },
        { text: "My Wishlist", path: "/mywishlist", permissionname: "My Wishlist" },
        { text: "Addresses", path: "/addresses", permissionname: "Addresses" },
        */
        {
            text: "Profile",
            path: "/myprofile",
            permissionname: "showmyprofile",
            icon: PersonOutlinedIcon,
        },
        {
            text: "Payment Methods",
            path: "/paymethods",
            permissionname: "showmodifycreditcards",
            icon: PaymentsOutlinedIcon,
        },
        /*{
            text: "My Admin",
            path: "/myadmin",
            permissionname: "showmyadmin",
            icon: SettingsOutlinedIcon,
        },*/
    ];

    function getSettingByName(name: string) {
        var value = "";

        if (!settings || settings.length === 0) return value;

        try {
            value =
                settings.find((x: any) => x.name.trim().toLowerCase() === name.trim().toLowerCase())
                    ?.value || "1";
        } catch (err) {
            console.log("Error getting setting by name " + name + ".", err);
        }
        return value.trim().toLowerCase();
    }

    function getMenuItems() {
        var mi = [] as any[];
        for (var i = 0; i < menuItems.length; i++) {
            if (getSettingByName(menuItems[i].permissionname) === "1") mi.push(menuItems[i]);
        }
        setPermissionMenuItems(mi);
    }

    useEffect(() => {
        getMenuItems();
    }, [settings]);

    return (
        <Box sx={{ width: "250px" }}>
            <Typography
                variant='h6'
                sx={{ mb: 5, fontWeight: 600, fontSize: "1.25rem" }}
            >
                My Account
            </Typography>
            <List>
                {permissionMenuItems.map((item, index) => (
                    <Button
                        key={index}
                        onClick={() => {
                            onButtonClick(item.path);
                            navigate(item.path);
                        }}
                        sx={{
                            "justifyContent": "flex-start",
                            "width": "100%",
                            "height": "3rem",
                            "textTransform": "none",
                            "mb": 1,
                            "fontWeight": 600,
                            "borderRadius": "8px",
                            "backgroundColor": theme.palette.common.white,
                            "borderColor":
                                isActive === item.path ?
                                    theme.palette.primary.dark
                                :   theme.palette.common.white,
                            "&:hover": {
                                borderColor: theme.palette.primary.dark,
                            },
                        }}
                        variant='outlined'
                        startIcon={React.createElement(item.icon)}
                    >
                        <ListItemText
                            primary={item.text}
                            sx={{ textAlign: "left" }}
                        />
                    </Button>
                ))}
            </List>
        </Box>
    );
}

export default AccountMenu;
