import React from "react";
/*import { NumberFormatBase, NumericFormat } from 'react-number-format';
import { PropsWithChildren} from "react";
import { NumericFormatProps } from "react-number-format";
import { BaseType } from 'typescript';*/

const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
});

interface ReactNativeNumberFormatProps {
    value: number | bigint | string;
}

function isNumeric(props: ReactNativeNumberFormatProps) {
    if (typeof props.value != "string") return true; // if the type is number or bigint, we assume it's a number

    return !isNaN(parseFloat(props.value.toString()));
}

export const ReactNativeNumberFormat: React.FC<ReactNativeNumberFormatProps> = (props) => {
    return (
        <>
            <b>
                {props.value && isNumeric(props) ?
                    formatter.format(parseFloat(props.value.toString()))
                :   "$0.00"}
            </b>
            {/*<NumericFormat
            displayType={"text"}
            thousandSeparator=","
            decimalScale={2}
            fixedDecimalScale
            prefix={"$"}
            renderText={(formattedValue) => <b>{formattedValue}</b>} >
        </NumericFormat>*/}
        </>
    );
};
