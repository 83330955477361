import { ButtonProps } from "@mui/material/Button";
import { Button } from "@mui/material";
import { styled as muistyled, styled } from "@mui/material/styles";

export const GreenButton = muistyled(Button)(({ theme }) => ({
    "textTransform": "none",
    "borderColor": "#58B55D",
    "color": "white",
    "backgroundColor": "#58B55D",
    "fontFamily": "Montserrat",
    "fontSize": "14px",
    "fontWeight": "600",
    "lineHeight": "22px",
    "letterSpacing": "0em",
    "textAlign": "center",
    "&:hover": {
        backgroundColor: "#439747",
        color: "white",
    },
}));

export const WhiteButton = muistyled(Button)(({ theme }) => ({
    "color": "black",
    "backgroundColor": "white",
    "borderColor": "#58B55D",
    "textTransform": "none",
    "fontFamily": "Montserrat",
    "fontSize": "14px",
    "fontWeight": "600",
    "lineHeight": "22px",
    "letterSpacing": "0em",
    "textAlign": "center",
    "&:hover": {
        color: "black",
        backgroundColor: "#efeff1",
    },
}));

export const SmallIconButton = muistyled(Button)(({ theme }) => ({
    "color": "black",
    "minWidth": "30px",
    "maxWidth": "30px",
    "padding": "0",
    "&:hover": {
        borderColor: "#58B55D",
        color: "black",
    },
}));

export const GreenTextButton = styled(Button)<ButtonProps>(({ theme }) => ({
    "color": "#58B55D",
    "textTransform": "none",
    "&:hover": {
        color: "#58B56D",
    },
}));
