import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Typography,
    Button,
    Paper,
    Avatar,
    styled,
    Grid,
    Divider,
    TextField,
} from "@mui/material";
import theme from "../theme/theme";
import { SelectUser } from "services/GraphQlHelper";
import { GetUserName } from "../services/GetInvoiceData";
import { type WithAuthenticatorProps } from "@aws-amplify/ui-react";
import { fetchUserAttributes, updatePassword, type UpdatePasswordInput } from "aws-amplify/auth";
import { useIsMobile } from "../helpers/CustomHooks";

const ItemHeader = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    mt: 2,
    mb: 2,
}));

const ItemBody = styled(Paper)(({ theme }) => ({
    fontWeight: 400,
    padding: 10,
    textAlign: "left",
    boxShadow: "none",
    border: "1px solid #dcdcdc",
    borderRadius: "8px",
    marginTop: 5,
    marginBottom: 20,
}));

const tempUser = {
    name: "Your Name",
    firstName: "Name",
    imageUrl: "https://prism-web-images.s3.amazonaws.com/user-profile/default_user_profile_pic.jpg",
};

async function handleFetchUserAttributes() {
    try {
        const userAttributes = await fetchUserAttributes();
        console.log("User attributes: ", userAttributes);

        return userAttributes;
    } catch (error) {
        console.error("Error fetching user attributes.", error);

        return null;
    }
}

async function handleUpdatePassword(
    oldPassword: string,
    newPassword: string,
    setUpdateError: (error: string | null) => void,
) {
    const input: UpdatePasswordInput = {
        oldPassword,
        newPassword,
    };

    try {
        const result = await updatePassword(input);
        console.log("Password was updated successfully.", result);
        setUpdateError(null);
    } catch (error) {
        console.error("Error updating password.", error);
        setUpdateError("Error updating password. Please try again.");
    }
}

interface ChangePasswordProps {
    showChangePassword: boolean;
    updateError: string | null;
    setShowChangePassword: (showChangePassword: boolean) => void;
    setOldPassword: (password: string) => void;
    setNewPassword: (password: string) => void;
    setUpdateError: (error: string | null) => void;
    oldPassword: string;
    newPassword: string;
}

const ChangePassword = ({
    showChangePassword,
    updateError,
    setShowChangePassword,
    setOldPassword,
    setNewPassword,
    setUpdateError,
    oldPassword,
    newPassword,
}: ChangePasswordProps) => {
    const handleUpdatePasswordClick = async () => {
        await handleUpdatePassword(oldPassword, newPassword, setUpdateError);
        setShowChangePassword(false);
    };

    const handleChangePasswordClick = async () => {
        await setShowChangePassword(true);
        setUpdateError(null);
    };

    if (showChangePassword === false) {
        return (
            <Grid
                item
                xs={12}
            >
                <ItemHeader>Password</ItemHeader>
                <ItemBody>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography>********</Typography>
                        <Button
                            variant='text'
                            onClick={() => handleChangePasswordClick()}
                            sx={{
                                "color": theme.palette.primary.main,
                                "fontWeight": 500,
                                "&:hover": {
                                    color: theme.palette.primary.dark,
                                    backgroundColor: "transparent",
                                    fontWeight: 600,
                                },
                            }}
                        >
                            Change Password
                        </Button>
                    </Box>
                </ItemBody>
                <ItemBody sx={{ border: "none" }}>
                    <Box>{updateError && <Typography color='error'>{updateError}</Typography>}</Box>
                </ItemBody>
            </Grid>
        );
    } else {
        return (
            <Grid
                item
                xs={12}
            >
                <ItemHeader>Password</ItemHeader>
                <ItemBody>
                    <Grid
                        container
                        spacing={2}
                        direction='column'
                    >
                        <Grid item>
                            <TextField
                                fullWidth
                                type='password'
                                label='Enter Current Password'
                                onChange={(e) => setOldPassword(e.target.value)}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                type='password'
                                label='Enter New Password'
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={6}
                            >
                                <Button
                                    variant='outlined'
                                    fullWidth
                                    onClick={() => setShowChangePassword(false)}
                                    sx={{
                                        "color": theme.palette.primary.main,
                                        "fontWeight": 500,
                                        "boxShadow": "none",
                                        "borderRadius": "8px",
                                        "&:hover": {
                                            color: theme.palette.primary.dark,
                                            backgroundColor: "transparent",
                                            fontWeight: 600,
                                        },
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                            >
                                <Button
                                    variant='contained'
                                    fullWidth
                                    onClick={() => handleUpdatePasswordClick()}
                                    sx={{
                                        "color": theme.palette.common.white,
                                        "fontWeight": 500,
                                        "boxShadow": "none",
                                        "borderRadius": "8px",
                                        "&:hover": {
                                            backgroundColor: theme.palette.primary.dark,
                                            boxShadow: "none",
                                            fontWeight: 600,
                                        },
                                    }}
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </ItemBody>
            </Grid>
        );
    }
};

const ProfileAutoGrid = ({ signOut, user }: WithAuthenticatorProps) => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [isSmScreen, setIsSmScreen] = React.useState(window.innerWidth < 1000);
    const [userAttributes, setUserAttributes] = React.useState<any | null>(null);
    const [userEmail, setUserEmail] = React.useState<string | null>(null);
    const [oldPassword, setOldPassword] = React.useState<string>("");
    const [newPassword, setNewPassword] = React.useState<string>("");
    const [showChangePassword, setShowChangePassword] = React.useState<boolean>(false);
    const [updateError, setUpdateError] = React.useState<string | null>(null);
    const [firstName, setFirstName] = React.useState<string>("");
    const [lastName, setLastName] = React.useState<string>("");

    const navigate = useNavigate();
    const isMobile = useIsMobile();

    useEffect(() => {
        const handleResizeWindow = () => {
            const newWidth = window.innerWidth;
            setWidth(newWidth);
            setIsSmScreen(newWidth < 1000);
        };

        window.addEventListener("resize", handleResizeWindow);
        handleResizeWindow();

        const fetchUserAttributes = async () => {
            const userAttributes = await handleFetchUserAttributes();
            if (userAttributes && userAttributes.email) {
                setUserAttributes(userAttributes);
                setUserEmail(userAttributes.email);
                console.log("User Attributes: ", userAttributes);
            }
        };

        const fetchUserInfo = async () => {
            var user = await SelectUser(GetUserName());
            if (user) {
                if (user.data?.userByUsername?.items[0]) {
                    console.log("532: fetchUserInfo", user.data.userByUsername.items[0]);
                    setFirstName(user.data.userByUsername.items[0].firstname || "");
                    setLastName(user.data.userByUsername.items[0].lastname || "");
                }
            }
        };

        fetchUserAttributes();
        fetchUserInfo();
        return () => {
            window.removeEventListener("resize", handleResizeWindow);
        };
    }, []);

    return (
        <Box sx={{ flexGrow: 1, position: "relative", width: isMobile ? "100vw" : "500px" }}>
            <Typography
                variant='h4'
                sx={{ mb: 2, fontWeight: 600, fontSize: "1.25rem" }}
            >
                Profile
            </Typography>
            <Paper sx={{ p: 2, boxShadow: "none", border: "1px solid #dcdcdc" }}>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        container
                        item
                        xs={12}
                        spacing={3}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={8}
                        >
                            <Grid
                                item
                                xs={12}
                                sx={{ mt: 2, mb: 2, fontWeight: 600, fontSize: "1.1rem" }}
                            >
                                Personal Information
                            </Grid>

                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <ItemHeader>First Name</ItemHeader>
                                    <ItemBody>{firstName}</ItemBody>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <ItemHeader>Last Name</ItemHeader>
                                    <ItemBody>{lastName}</ItemBody>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <ItemHeader>Email Address</ItemHeader>
                                <ItemBody>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography>{userEmail}</Typography>
                                        <Button
                                            variant='text'
                                            disabled
                                            sx={{
                                                "color": theme.palette.primary.main,
                                                "fontWeight": 500,
                                                "&:hover": {
                                                    color: theme.palette.primary.dark,
                                                    backgroundColor: "transparent",
                                                    fontWeight: 600,
                                                },
                                            }}
                                        ></Button>
                                    </Box>
                                </ItemBody>
                            </Grid>
                            <ChangePassword
                                showChangePassword={showChangePassword}
                                setShowChangePassword={setShowChangePassword}
                                oldPassword={oldPassword}
                                newPassword={newPassword}
                                updateError={updateError}
                                setOldPassword={setOldPassword}
                                setNewPassword={setNewPassword}
                                setUpdateError={setUpdateError}
                            />
                            {/*
                            <Grid
                                item
                                xs={12}
                            >
                                <ItemHeader>Phone Number</ItemHeader>
                                <ItemBody>(555) 555-55555</ItemBody>
                            </Grid>
                            */}
                        </Grid>
                        {/*
                        {!isSmScreen && (
                            <>
                                <Grid
                                    item
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}
                                    width={2}
                                >
                                    <Divider
                                        orientation='vertical'
                                        sx={{
                                            height: "100%",
                                            mx: 1,
                                            borderWidth: 1,
                                            borderColor: "#dcdcdc",
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                    sx={{ position: "relative" }}
                                >
                                    <Avatar
                                        alt={tempUser.name}
                                        src={tempUser.imageUrl}
                                        sx={{ width: "10rem", height: "10rem", mb: 2 }}
                                    />
                                    <Typography
                                        sx={{
                                            textAlign: "center",
                                            fontWeight: 500,
                                            fontSize: "1rem",
                                        }}
                                    >
                                        Account # : 123456789
                                    </Typography>
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        */}
                        {/*
                        <Button
                            variant='outlined'
                            disableElevation
                            fullWidth
                            sx={{
                                "color": theme.palette.text.primary,
                                "fontWeight": 600,
                                "&:active": {
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.common.white,
                                },
                            }}
                        >
                            Update Profile
                        </Button>
                        */}
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
};

const MyProfilePage = () => {
    return <ProfileAutoGrid />;
};

export default MyProfilePage;
