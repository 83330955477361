import { useEffect, useMemo, useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Typography,
    Grid,
    TextField,
    InputAdornment,
    Pagination,
    Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
    MaterialReactTable,
    useMaterialReactTable,
    type MRT_ColumnDef,
    type MRT_RowSelectionState,
} from "material-react-table";
import LoadingSpinner from "../components/LoadingSpinner";
import { truncateString } from "../helpers/truncateString";
import { UserInvoices } from "../interfaces/InvoiceInterfaces";
import {
    API_Authenticate,
    API_GetInvoiceReport,
    API_GetUserInvoices,
    GetUserName,
} from "../services/GetInvoiceData";
import { ReactNativeNumberFormat } from "../components/ReactNativeNumberFormat";
import theme from "../theme/theme";

const PrintInvoices = () => {
    const [userInvoices, setUserInvoices] = useState<UserInvoices[]>([]);
    const [authToken, setAuthToken] = useState("");
    const [loading, setLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoadingReport, setIsLoadingReport] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const [isSmScreen, setIsSmScreen] = useState(window.innerWidth < 1000);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(5);

    const navigate = useNavigate();
    let atok = "";

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const filteredData = useMemo(() => {
        if (!searchTerm) return userInvoices;
        return userInvoices.filter((invoice) => {
            return [
                invoice.cinvno,
                invoice.csono,
                invoice.cpono,
                invoice.dorder,
                invoice.dcutoff,
                invoice.ccustno,
                invoice.cscono,
                invoice.csaddr1,
                invoice.cscity,
                invoice.csstate,
                invoice.cszip,
                invoice.cscompany,
            ].some((field) => field && field.toLowerCase().includes(searchTerm));
        });
    }, [userInvoices, searchTerm]);

    useEffect(() => {
        const handleResizeWindow = () => {
            const newWidth = window.innerWidth;
            setWidth(newWidth);
            setIsSmScreen(newWidth < 1000);
        };

        window.addEventListener("resize", handleResizeWindow);
        handleResizeWindow();

        return () => {
            window.removeEventListener("resize", handleResizeWindow);
        };
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                console.log("Print Invoices: Calling Authenticate");
                const token = await API_Authenticate();
                if (token && token !== "") console.log("Authenticated");
                else {
                    console.log("Authentication failed...");
                    return;
                }
                setAuthToken(token);
                atok = token;
                console.log("Print Invoices: Calling GetUserInvoices");
                const invoiceData = await API_GetUserInvoices(token, GetUserName());
                console.log("Invoice Data: ", invoiceData);
                if (invoiceData) {
                    const data = invoiceData?.data;

                    setUserInvoices(data);
                }
            } catch (error) {
                console.error("Failed to load open invoices: ", error);
            } finally {
                setLoading(false);
            }
        };
        console.log("Print Invoices: fetching data");
        fetchData();
    }, []);

    const showReport = async (cuid: string, cinvno: string) => {
        try {
            setIsLoadingReport(true);

            console.log("Show Report for " + cuid);
            const token = await API_Authenticate();

            await API_GetInvoiceReport(token, cuid, cinvno);
        } catch (error) {
        } finally {
            setIsLoadingReport(false);
        }
    };

    const columns = useMemo<MRT_ColumnDef<UserInvoices>[]>(
        () => [
            {
                accessorKey: "ccustno",
                header: "Customer#",
                enableEditing: false,
                size: 50,
            },
            {
                accessorKey: "cinvno",
                header: "Invoice#",
                enableEditing: false,
                size: 50,
                Cell: ({ row }) => {
                    return isSmScreen ?
                            <Box
                                sx={{
                                    display: "grid",
                                    margin: "auto",
                                    gridTemplateColumns: "1fr",
                                    width: "100%",
                                }}
                            >
                                <Typography>
                                    Invoice#:{" "}
                                    <a
                                        href='#'
                                        onClick={() => {
                                            showReport("", row.getValue<string>("cinvno"));
                                        }}
                                        style={{
                                            color: theme.palette.primary.main,
                                            textDecoration: "none",
                                        }}
                                    >
                                        {row.getValue<string>("cinvno")}
                                    </a>
                                </Typography>
                                <Typography>Customer#: {row.original.ccustno}</Typography>
                                <Typography>PO#: {row.original.cpono}</Typography>
                                <Typography>
                                    Date: {truncateString(row.original.dorder, 10)}
                                </Typography>
                                <Typography>
                                    Site Address:
                                    <div className='flex space-x-2'>
                                        <span className='max-w-[500px] truncate font-medium text-xs'>
                                            {row.getValue("cscompany")}
                                            <br />
                                            {row.getValue("csaddr1")}
                                            <br />
                                            {row.getValue("cscity")}, {row.getValue("csstate")}{" "}
                                            {row.getValue("cszip")}
                                        </span>
                                    </div>
                                </Typography>
                                <Typography>
                                    Total:{" "}
                                    {ReactNativeNumberFormat({ value: row.getValue("ntotal") })}
                                </Typography>
                                <Typography>
                                    Balance:{" "}
                                    {ReactNativeNumberFormat({ value: row.getValue("nbalance") })}
                                </Typography>
                            </Box>
                        :   <>
                                <a
                                    href='#'
                                    onClick={() => {
                                        showReport("", row.getValue<string>("cinvno"));
                                    }}
                                    style={{
                                        color: theme.palette.primary.main,
                                        textDecoration: "underline",
                                    }}
                                >
                                    {row.getValue<string>("cinvno")}
                                </a>
                            </>;
                },
            },
            {
                accessorKey: "cpono",
                header: "PO#",
                size: 50,
                enableEditing: false,
                Cell: ({ row }) => {
                    return <div>{row.getValue<string>("cpono")}</div>;
                },
            },
            {
                accessorKey: "cscono",
                header: "Site#",
                enableEditing: false,
            },
            {
                accessorKey: "csaddr1",
                header: "Address",
                enableEditing: false,
            },
            {
                accessorKey: "cscity",
                header: "City",
                enableEditing: false,
            },
            {
                accessorKey: "csstate",
                header: "State",
                enableEditing: false,
            },
            {
                accessorKey: "cszip",
                header: "Zip",
                enableEditing: false,
            },
            {
                accessorKey: "dorder",
                header: "Date",
                enableEditing: false,
                size: 50,
                Cell: ({ row }) => {
                    return (
                        <div className='w-[70px] font-medium text-xs'>
                            {truncateString(row.getValue("dorder"), 10)}
                        </div>
                    );
                },
            },
            {
                accessorKey: "cscompany",
                header: "Company/Address",
                enableEditing: false,
                size: 150,
                Cell: ({ row }) => {
                    return (
                        <div className='flex space-x-2'>
                            <span className='max-w-[500px] truncate font-medium text-xs'>
                                {row.getValue("cscompany")}
                                <br />
                                {row.getValue("csaddr1")}
                                <br />
                                {row.getValue("cscity")}, {row.getValue("csstate")}{" "}
                                {row.getValue("cszip")}
                            </span>
                        </div>
                    );
                },
            },
            {
                accessorKey: "ntotal",
                header: "Total",
                enableEditing: false,
                size: 50,
                Cell: ({ row }) => {
                    return (
                        <div className='flex space-x-2'>
                            <span className='max-w-[500px] truncate font-medium text-xs'>
                                {ReactNativeNumberFormat({ value: row.getValue("ntotal") })}
                            </span>
                        </div>
                    );
                },
            },
            {
                accessorKey: "nbalance",
                header: "Balance",
                enableEditing: false,
                size: 50,
                Cell: ({ row }) => {
                    return (
                        <div className='flex space-x-2'>
                            <span className='max-w-[500px] truncate text-sm font-medium text-xs'>
                                {ReactNativeNumberFormat({ value: row.getValue("nbalance") })}
                            </span>
                        </div>
                    );
                },
            },
            {
                accessorKey: "printbutton",
                header: "",
                enableEditing: false,
                maxSize: 125,
                Cell: ({ row }) => {
                    return (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Button
                                variant='outlined'
                                disableElevation
                                fullWidth
                                onClick={() => {
                                    showReport("", row.getValue<string>("cinvno"));
                                }}
                                sx={{
                                    "color": theme.palette.text.primary,
                                    "fontWeight": 600,
                                    "&:active": {
                                        backgroundColor: theme.palette.primary.main,
                                        color: theme.palette.common.white,
                                    },
                                }}
                            >
                                Print
                            </Button>
                        </Box>
                    );
                },
            },
        ],
        [isSmScreen], //end
    );

    //optionally, you can manage the row selection state yourself
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

    const table = useMaterialReactTable({
        columns,
        data: filteredData, // Using the state data.
        enableRowSelection: false,
        enableTopToolbar: false,
        enableBottomToolbar: false,
        enableEditing: false,
        editDisplayMode: "cell",
        enableColumnActions: false,
        enableColumnFilters: false,
        initialState: {
            columnVisibility: {
                csaddr1: false,
                cscity: false,
                csstate: false,
                cszip: false,
                cscono: false,
                cpono: !isSmScreen,
                ccustno: !isSmScreen,
                dorder: !isSmScreen,
                cscompany: !isSmScreen,
                ntotal: !isSmScreen,
                nbalance: !isSmScreen,
            },
        },
        getRowId: (row) => row.cinvno, //give each row a more useful id
        state: { rowSelection, pagination: { pageIndex: page, pageSize: pageSize } }, //pass our managed row selection state to the table to use
        muiTableBodyCellProps: {
            sx: {
                backgroundColor: theme.palette.common.white,
            },
        },
        muiTableHeadCellProps: {
            sx: {
                backgroundColor: theme.palette.common.white,
            },
        },
    });

    useEffect(() => {
        console.log("resetting column sizing...  useEffect: ", isSmScreen);
        if (!loading && table) {
            console.log("resetting column sizing...  small screen=", isSmScreen);
            table.setColumnVisibility({
                cuid: false,
                csaddr1: false,
                cscity: false,
                csstate: false,
                cszip: false,
                cscono: false,
                cpono: !isSmScreen,
                ccustno: !isSmScreen,
                dorder: !isSmScreen,
                cscompany: !isSmScreen,
                ntotal: !isSmScreen,
                nbalance: !isSmScreen,
            });
        }
    }, [isSmScreen]);

    const startIndex = page * pageSize + 1;
    const endIndex = Math.min(startIndex + pageSize - 1, userInvoices.length);
    const totalPages = Math.ceil(userInvoices.length / pageSize);

    return (
        <Box style={{ position: "relative" }}>
            <Grid
                container
                spacing={2}
                alignItems='center'
                justifyContent='space-between'
                sx={{ mb: 2 }}
            >
                <Grid
                    item
                    xs={10}
                    sm={8}
                    md={6}
                >
                    <TextField
                        variant='outlined'
                        placeholder='Search Invoices'
                        value={searchTerm}
                        onChange={handleSearchChange}
                        fullWidth
                        sx={{
                            mb: 2,
                            backgroundColor: theme.palette.common.white,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
            {loading || isLoadingReport ?
                <LoadingSpinner
                    message={isLoadingReport ? "Loading Report..." : "Loading Tickets..."}
                />
            : isSaving ?
                <LoadingSpinner message='Saving...' />
            :   <MaterialReactTable table={table} />}
            <Typography
                variant='caption'
                sx={{ float: "left", mt: 2 }}
            >
                Showing {startIndex} to {endIndex} of {userInvoices.length} entries.
            </Typography>
            <Pagination
                count={totalPages}
                page={page + 1}
                onChange={(event, value) => setPage(value - 1)}
                showFirstButton
                showLastButton
                sx={{
                    "float": "right",
                    "mt": 2,
                    "& .MuiPaginationItem-root": {
                        "borderRadius": "4px",
                        "backgroundColor": theme.palette.common.white,
                        "color": theme.palette.text.primary,
                        "border": `1px solid ${theme.palette.text.primary}`,
                        "&:hover": {
                            color: theme.palette.primary.main,
                            border: `2px solid ${theme.palette.primary.main}`,
                            backgroundColor: theme.palette.common.white,
                            fontWeight: 600,
                        },
                        "&.Mui-selected": {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.common.white,
                        },
                    },
                }}
            />
        </Box>
    );
};

export default PrintInvoices;
