import { styled } from "@mui/material";
import { Box } from "@mui/material";

const TestPageContainer = styled("div")(({ theme }) => ({
    // Add styles here.
}));

const TestPage = () => (
    <TestPageContainer>
        <Box>
            This is a test page used as a temporary placeholder for components and navigation until
            an appropriate page/component is built.
        </Box>
    </TestPageContainer>
);

export default TestPage;
