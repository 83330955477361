import {
    Box,
    Button,
    ButtonProps,
    Card,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Hidden,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import { GreenTextButton } from "./Buttons";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CCProfile, CreditCard } from "interfaces/InvoiceInterfaces";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CreditCardInformation from "./CreditCardInformation";
import ConfirmDialog from "./ConfirmDialog";
import {
    API_Authenticate,
    API_DeleteCCProfile,
    API_SaveCCProfile,
    API_SetDefaultCCProfile,
    GetUserName,
} from "services/GetInvoiceData";
import "../styles.css";
import { CustomDivider } from "./CustomDivider";
import { useIsMobile } from "helpers/CustomHooks";

type Props = {
    paymentInfo: CreditCard;
    ccustno: string;
    setReloadData: React.Dispatch<React.SetStateAction<boolean>>;
};

const cardImages = [
    {
        type: "visa",
        src: "https://prism-web-images.s3.amazonaws.com/prism_images/visa.svg",
    },
    {
        type: "master",
        src: "https://prism-web-images.s3.amazonaws.com/prism_images/mastercard.svg",
    },
    {
        type: "amex",
        src: "https://prism-web-images.s3.amazonaws.com/prism_images/amex.svg",
    },
    {
        type: "discover",
        src: "https://prism-web-images.s3.amazonaws.com/prism_images/discover.svg",
    },
    {
        type: "generic",
        src: "https://prism-web-images.s3.amazonaws.com/prism_images/generic.svg",
    },
];

const CreditCardTile: React.FC<Props> = ({ paymentInfo, ccustno, setReloadData }) => {
    const [cardImage, setCardImage] = React.useState({} as any);
    const [editCC, setEditCC] = React.useState(false);
    const [confirmDelete, setConfirmDelete] = React.useState(false);
    const [confirmDefault, setConfirmDefault] = React.useState(false);

    const isMobile = useIsMobile();

    const getCardImage = (type: string) => {
        if (!type) return cardImages.find((card) => card.type === "generic");

        type = type.trim().toLowerCase();
        const cardImage = cardImages.find((card) => card.type === type);
        if (cardImage) return cardImage;

        return cardImages.find((card) => card.type === "generic");
    };

    useEffect(() => {
        if (!paymentInfo) return;

        setCardImage(getCardImage(paymentInfo.ccardtype));
        console.log("type: ", paymentInfo.ccardtype);
        console.log("cardImage: ", getCardImage(paymentInfo.ccardtype));
    }, [paymentInfo]);

    function handleClose(nprofid: string, saved: boolean): void {
        setEditCC(false);
    }

    async function deleteCreditCard(nprofid: BigInt) {
        var token = await API_Authenticate();

        var res = await API_DeleteCCProfile(token, paymentInfo.payment_profile_id);

        if (res && res.IsSuccessful) {
            setReloadData(true);
        } else if (res.Error) alert(res.Error);
        else alert("Unknown error deleting credit card.");
    }

    async function makeCreditCardDefault(nprofid: BigInt) {
        var token = await API_Authenticate();

        var res = await API_SetDefaultCCProfile(token, paymentInfo.payment_profile_id);
        if (res && res.IsSuccessful) {
            setReloadData(true);
        } else if (res.Error) alert(res.Error);
        else alert("Unknown error updating credit card.");
    }

    const mobileView = (
        <Stack
            direction='column'
            spacing={1}
            alignItems={"left"}
            sx={{ ml: "5px" }}
        >
            <Stack
                direction='row'
                spacing={1}
                alignItems={"left"}
                sx={{ ml: "5px" }}
            >
                <Box
                    width={"40%"}
                    component='img'
                    src={cardImage && cardImage.src}
                    alt={paymentInfo.ccardtype}
                    sx={{ width: "50px", height: "50px" }}
                />
                <Box>
                    <Box className='cc-text-bold'>{paymentInfo.ccardnum}</Box>
                    <Box className='cc-text'>Expires: {paymentInfo.cexpdate}</Box>
                    <Box className='cc-text'>
                        {paymentInfo.cfirstname + " " + paymentInfo.clastname}
                    </Box>
                </Box>
            </Stack>
            <Box>
                <Box className='cc-text-bold'>{paymentInfo.ccompany}</Box>
                <Box className='cc-text'>
                    {paymentInfo.caddr},{" "}
                    {paymentInfo.ccity + ", " + paymentInfo.cstate + "  " + paymentInfo.czip}
                </Box>
            </Box>
            <CustomDivider />
            <Stack
                direction='row'
                justifyContent={"space-between"}
            >
                {paymentInfo.ldefault === 1 ?
                    <Typography
                        sx={{ fontSize: 14, color: "black" }}
                        textAlign={"center"}
                        justifyContent={"center"}
                        className='filters_clearall'
                    >
                        <CheckCircleIcon sx={{ fontSize: 21, color: "#58B55D" }} />
                        &nbsp;&nbsp;Default
                    </Typography>
                :   <GreenTextButton
                        variant='text'
                        className='filters_clearall'
                        onClick={() => setConfirmDefault(true)}
                    >
                        Make Default
                    </GreenTextButton>
                }
                <Stack direction='row'>
                    <IconButton
                        aria-label='edit'
                        size='small'
                        onClick={() => setEditCC(true)}
                    >
                        <EditOutlinedIcon />
                    </IconButton>
                    <IconButton
                        aria-label='edit'
                        size='small'
                        onClick={() => setConfirmDelete(true)}
                    >
                        <DeleteOutlinedIcon />
                    </IconButton>
                </Stack>
            </Stack>
        </Stack>
    );

    const desktopView = (
        <Stack
            direction='row'
            spacing={5}
            alignItems={"center"}
            sx={{ ml: "20px" }}
        >
            <Box
                width={"100px"}
                component='img'
                src={cardImage && cardImage.src}
                alt={paymentInfo.ccardtype}
                sx={{ width: "50px", height: "50px" }}
            />
            <Box width='150px'>
                <Box className='cc-text-bold'>{paymentInfo.ccardnum}</Box>
                <Box className='cc-text'>Expires: {paymentInfo.cexpdate}</Box>
                <Box className='cc-text'>
                    {paymentInfo.cfirstname + " " + paymentInfo.clastname}
                </Box>
            </Box>
            <Box width='300px'>
                <Box className='cc-text-bold'>{paymentInfo.ccompany}</Box>
                <Box className='cc-text'>{paymentInfo.caddr}</Box>
                <Box className='cc-text'>
                    {paymentInfo.ccity + ", " + paymentInfo.cstate + "  " + paymentInfo.czip}
                </Box>
            </Box>
            <Stack
                direction='column'
                width='150px'
            >
                {paymentInfo.ldefault === 1 ?
                    <Typography
                        sx={{ fontSize: 14, color: "black" }}
                        textAlign={"center"}
                        justifyContent={"center"}
                        className='filters_clearall'
                    >
                        <CheckCircleIcon sx={{ fontSize: 21, color: "#58B55D" }} />
                        &nbsp;&nbsp;Default
                    </Typography>
                :   <GreenTextButton
                        variant='text'
                        className='filters_clearall'
                        onClick={() => setConfirmDefault(true)}
                    >
                        Make Default
                    </GreenTextButton>
                }
                <Stack
                    justifyContent={"center"}
                    direction='row'
                >
                    <IconButton
                        aria-label='edit'
                        size='small'
                        onClick={() => setEditCC(true)}
                    >
                        <EditOutlinedIcon />
                    </IconButton>
                    <IconButton
                        aria-label='edit'
                        size='small'
                        onClick={() => setConfirmDelete(true)}
                    >
                        <DeleteOutlinedIcon />
                    </IconButton>
                </Stack>
            </Stack>
        </Stack>
    );

    return (
        <>
            <Dialog open={editCC}>
                <DialogTitle>
                    <Typography
                        variant='h6'
                        sx={{ textAlign: "center" }}
                    >
                        Edit Credit Card
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <CreditCardInformation
                        ccustno={ccustno}
                        data={paymentInfo}
                        isNew={false}
                        onClose={handleClose}
                    />
                </DialogContent>
            </Dialog>

            <Card sx={{ width: isMobile ? "100%" : "750px", m: "auto" }}>
                <ConfirmDialog
                    title='Delete Credit Card'
                    open={confirmDelete}
                    onConfirm={() => {
                        deleteCreditCard(paymentInfo.nprofid);
                    }}
                    setOpen={setConfirmDelete}
                    children={
                        <Typography>Are you sure you want to delete this credit card?</Typography>
                    }
                />
                <ConfirmDialog
                    title='Make Default?'
                    open={confirmDefault}
                    onConfirm={() => {
                        makeCreditCardDefault(paymentInfo.nprofid);
                    }}
                    setOpen={setConfirmDefault}
                    children={
                        <Typography>
                            Are you sure you want to make this credit card default?
                        </Typography>
                    }
                />

                {isMobile ? mobileView : desktopView}
            </Card>
        </>
    );
};

export default CreditCardTile;
