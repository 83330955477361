import * as React from "react";
//import { experimentalStyled as styled } from '@mui/material/styles';
import { Button, Flex } from "@aws-amplify/ui-react";
import { Checkbox, Dialog, FormControl, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { getOverrideProps } from "../ui-components/utils";

import { TextField } from "@aws-amplify/ui-react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
    API_Authenticate,
    API_GetSettings,
    API_SaveSettings,
    GetTenantName,
    Settings,
    defaultSettings,
} from "../services/GetInvoiceData";
import { useIsMobile } from "helpers/CustomHooks";
import UploadFile from "helpers/S3ImageStorage";

/*const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));*/

interface UpdateClientProps {
    onSuccess: () => void;
    onCancel: () => void;
}

export default function UpdateSettings(props: UpdateClientProps) {
    //const [selectedPage, setSelectedPage] = React.useState("default");
    const [settings, setSettings] = React.useState([] as Settings[]);
    const [addRelationOpen, setAddRelationOpen] = React.useState(false);
    const [setting, setSetting] = React.useState({} as Settings);
    const [fileDialogOpen, setFileDialogOpen] = React.useState(false);
    const [saveURL, setSaveURL] = React.useState(false);
    const isMobile = useIsMobile();

    function UsersTable() {
        console.log("Calling UsersTable");
        return (
            <>
                <TableContainer component={Paper}>
                    <Table aria-label='simple table'>
                        <TableHead>
                            <TableRow>
                                {!isMobile && (
                                    <TableCell align='left'>
                                        <h5>Setting Name</h5>
                                    </TableCell>
                                )}
                                <TableCell align='left'>
                                    <h5>Setting Description</h5>
                                </TableCell>
                                <TableCell align='left'>
                                    <h5>Value</h5>
                                </TableCell>
                                <TableCell align='left'></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* {clients.sort((a, b) => (a > b ? 1 : -1)).map((row, index) => ( */}
                            {settings &&
                                settings
                                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                                    .map((row, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{
                                                "&:last-child td, &:last-child th": { border: 0 },
                                            }}
                                        >
                                            {!isMobile && (
                                                <TableCell
                                                    key={"11-" + index}
                                                    component='th'
                                                    scope='row'
                                                >
                                                    {row.name}
                                                </TableCell>
                                            )}
                                            <TableCell
                                                key={"1-" + index}
                                                component='th'
                                                scope='row'
                                            >
                                                {row.label}
                                            </TableCell>
                                            <TableCell
                                                key={"2-" + index}
                                                component='th'
                                                scope='row'
                                            >
                                                {row.value}
                                            </TableCell>
                                            <TableCell
                                                key={"3-" + index}
                                                align='right'
                                            >
                                                <Flex
                                                    key={"4-" + index}
                                                    direction='row'
                                                >
                                                    {(
                                                        row.name.toLowerCase().trim() ===
                                                        "websitelogo"
                                                    ) ?
                                                        <Button
                                                            key={"5-" + index}
                                                            onClick={() => {
                                                                setSetting(row);
                                                                setFileDialogOpen(true);
                                                            }}
                                                        >
                                                            Upload Logo
                                                        </Button>
                                                    :   <Button
                                                            key={"5-" + index}
                                                            onClick={() => {
                                                                setSetting(row);
                                                                setAddRelationOpen(true);
                                                            }}
                                                        >
                                                            Update
                                                        </Button>
                                                    }
                                                </Flex>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <UploadFile
                    setURL={setURL}
                    open={fileDialogOpen}
                    setOpen={setFileDialogOpen}
                    tenantId={GetTenantName()}
                    directory="logos"
                />
            </>
        );
    }

    function setURL(url: string) {
        console.log("setting: ", setting);
        console.log("url: ", url);

        setSetting({ name: setting.name, value: url });
        setSaveURL(true);
    }

    React.useEffect(() => {
        if(saveURL)
        {
            onSaveSettings();
            setSaveURL(false);
        }
        
    }, [saveURL]);

    async function onSaveSettings() {
        if (!setting) {
            alert("Could not get setting.  Please try again.");
            return;
        }
        try {
            var token = await API_Authenticate();
            var ret = await API_SaveSettings(token, [setting]);

            if (ret && ret.IsSuccessful) {
                console.log("Saved settings: ", ret);
                getSettingsData();
            } else {
                if (ret) {
                    alert(ret.Error);
                    console.log("Error when trying to save settings: ", ret);
                    return;
                } else {
                    alert("Unknown error when trying to save settings.");
                    return;
                }
            }

            /*settings.forEach((item, i) => { if (item.name == setting.name) settings[i].value = setting.value; });
            setSettings(settings);*/
        } catch (err) {
            alert(err);
            return;
        }
        setAddRelationOpen(false);
    }

    async function onSaveAllSettings() {
        try {
            var token = await API_Authenticate();
            var ret = await API_SaveSettings(token, settings);

            if (ret && ret.IsSuccessful) {
                console.log("Saved settings: ", ret);
                alert("Saved settings.");
                getSettingsData();
            } else {
                if (ret) {
                    alert(ret.Error);
                    console.log("Error when trying to save settings: ", ret);
                    return;
                } else {
                    alert("Unknown error when trying to save settings.");
                    return;
                }
            }
        } catch (err) {
            alert(err);
            return;
        }
        setAddRelationOpen(false);
    }

    function onSettingsClose(): void {
        setAddRelationOpen(false);
    }

    function AddRelationshipBox() {
        console.log("setting: ", setting);

        var settingFound = defaultSettings.find(
            (x) =>
                x.name.trim().toLocaleLowerCase() ===
                (setting?.name || "").trim().toLocaleLowerCase(),
        );
        if (settingFound) {
            if (setting) settingFound.value = setting?.value || "";

            console.log("setting found: ", settingFound);
            //setSetting(settingFound);
        } else
            settingFound = {
                name: setting?.name || "",
                value: setting?.value || "",
                type: "textbox",
                truevalue: "1",
                falsevalue: "0",
            };

        console.log("setting: ", setting);

        return (
            <Dialog
                open={addRelationOpen}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <div>
                    <FormControl sx={{ m: 1, minWidth: 300 }}>
                        <Typography
                            id='modal-modal-title'
                            variant='h6'
                            component='h2'
                            sx={{ marginBottom: "20px" }}
                        >
                            {settingFound && settingFound?.label !== "" ?
                                settingFound.label
                            :   settingFound.name}
                        </Typography>
                        <br />
                        <Box sx={{ marginRight: "10px", marginLeft: "10px" }}>
                            {settingFound.type === "textbox" ?
                                <TextField
                                    id='outlined-basic'
                                    label=''
                                    value={setting?.value}
                                    onChange={(e) =>
                                        setSetting({
                                            name: settingFound?.name || "",
                                            value: (e.target as HTMLInputElement).value,
                                        })
                                    }
                                />
                            :   null}
                            {settingFound.type === "checkbox" ?
                                <Checkbox
                                    id='cb-outlined-basic'
                                    checked={
                                        setting?.value?.trim()?.toLowerCase() ===
                                        settingFound?.truevalue?.trim()?.toLowerCase()
                                    }
                                    onChange={(e: any) =>
                                        setSetting({
                                            name: settingFound?.name || "",
                                            value:
                                                ((e.target as HTMLInputElement).checked ?
                                                    settingFound?.truevalue || "1"
                                                :   settingFound?.falsevalue || "0") || "0",
                                        })
                                    }
                                />
                            :   null}
                        </Box>

                        <br />
                        <br />

                        <Flex
                            width={"100%"}
                            marginTop={"20px"}
                            direction={"row"}
                            justifyContent={"space-between"}
                        >
                            <Button
                                className='float-left'
                                onClick={() => {
                                    onSettingsClose();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                className='float-right'
                                onClick={() => {
                                    onSaveSettings();
                                }}
                            >
                                Save
                            </Button>
                        </Flex>
                    </FormControl>
                </div>
            </Dialog>
        );
    }

    async function getSettingsData() {
        var token = await API_Authenticate();
        var data = await API_GetSettings(token);
        console.log("Got Settings: ", data);
        var allSettings: Settings[] = [];

        if (data?.Settings && data.Settings.length > 0) {
            allSettings = data.Settings;
        }

        defaultSettings.forEach((item, i) => {
            var found = allSettings.find(
                (x) => x.name.trim().toLocaleLowerCase() === item.name.trim().toLocaleLowerCase(),
            );
            if (!found) allSettings.push(item);
            else found.label = item.label;
        });

        setSettings(allSettings);
    }

    React.useEffect(() => {
        getSettingsData();
    }, []);

    function showPage() {
        return (
            <>
                <UsersTable />
                <br />
                <br />
                <Button
                    children='Save All Settings'
                    type='submit'
                    onClick={(event) => {
                        event.preventDefault();
                        onSaveAllSettings();
                    }}
                    {...getOverrideProps(null, "ClearButton")}
                ></Button>
                <br />
                <br />
                <Button
                    children='Go Back'
                    type='reset'
                    onClick={(event) => {
                        event.preventDefault();
                        props.onSuccess();
                    }}
                    {...getOverrideProps(null, "ClearButton")}
                ></Button>
            </>
        );

        /*  switch(selectedPage)
            {
                case "default":
                    return (
                        <>
                        <UsersTable />
                        <br/><br/>
                        <Button
                            children="Save All Settings"
                            type="submit"
                            onClick={(event) => {
                                event.preventDefault();
                                onSaveAllSettings();
                            }}
                            {...getOverrideProps(null, "ClearButton")}
                            ></Button>
                        <br/><br/>
                        <Button
                            children="Go Back"
                            type="reset"
                            onClick={(event) => {
                                event.preventDefault();
                                props.onSuccess();
                            }}
                            {...getOverrideProps(null, "ClearButton")}
                            ></Button>
                        </>
                    );
                default:
                    return (
                        <div>
                            <h3>{selectedPage}</h3>
                            
                        </div>
                    );
            }*/
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            {AddRelationshipBox()}
            {showPage()}
        </Box>
    );
}
