import { Box, styled } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cart from "../components/Cart/Cart";
import { ScheduleAddress, WebInventoryItem } from "../components/StoreItems";
import NavBreadcrumbs from "../helpers/NavBreadcrumbs";

const CartPageContainer = styled("div")(({ theme }) => ({
    // Add styles here.
    "max-width": "1440px",
}));

type Props = {
    cartItems: WebInventoryItem[];
    addToCart: (clickedItem: WebInventoryItem, updateQty: boolean) => void;
    removeFromCart: (citemno: string, nqty?: number, bAll?: boolean) => void;
    checkout: () => void;
    address: ScheduleAddress;
    setAddress: React.Dispatch<React.SetStateAction<ScheduleAddress>>;
    setOpenCart: React.Dispatch<React.SetStateAction<boolean>>;
    refreshCartItems: boolean;
    setRefreshCartItems: React.Dispatch<React.SetStateAction<boolean>>;
};

const CartPage: React.FC<Props> = ({
    cartItems,
    addToCart,
    removeFromCart,
    checkout,
    address,
    setAddress,
    setOpenCart,
    setRefreshCartItems,
    refreshCartItems,
}) => {
    const navigate = useNavigate();

    const gotoCheckout = () => {
        console.log("CCHKOUT: checkout: ", cartItems);
        console.log("CCHKOUT: address: ", address);
        if (!address || address?.ccustno === "") {
            alert("You need to pick a Schedule/Address before you can checkout");
            navigate("/products");
            return;
        }
        navigate("/cart/checkout");
    };

    useEffect(() => {
        console.log("CartPage: refreshCartItems: ", refreshCartItems);
        setRefreshCartItems(!refreshCartItems);
        setOpenCart(true);
    }, []);

    return (
        <Box sx={{ width: "100%", typography: "body1" }}>
            <NavBreadcrumbs currentView='cart' />
            <CartPageContainer>
                <Box sx={{ width: "100vw" }}>{/* This box is here for correct sizing */}</Box>
                <h1>Your Cart</h1>
                <Cart
                    cartItems={cartItems}
                    addToCart={addToCart}
                    removeFromCart={removeFromCart}
                    checkout={gotoCheckout}
                    address={address}
                    setAddress={setAddress}
                />
            </CartPageContainer>
        </Box>
    );
};

export default CartPage;
