import { createTheme } from "@mui/material/styles";
import { ThemeProvider, styled } from "@mui/system";
import { ThemeConsumer } from "styled-components";

const theme = createTheme({
    palette: {
        mode: "light",
        background: {
            default: "#F6F7F9",
        },
        primary: {
            main: "#58b55d",
        },
        secondary: {
            main: "#8e44ad",
        },
        text: {
            primary: "#1e2833",
            secondary: "#58b55d",
            disabled: "rgba(0,0,0,0.5)",
        },
        success: {
            main: "#2e8b57",
        },
        error: {
            main: "#dc143c",
        },
        info: {
            main: "#1e90ff",
        },
        warning: {
            main: "#daa520",
        },
        divider: "#dcdcdc",
    },
    typography: {
        fontFamily: "Montserrat",
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        h1: {
            fontSize: "6.5rem",
            fontWeight: 600,
            lineHeight: 1.2,
        },
        fontWeightBold: 600,
        h2: {
            fontSize: "5.5rem",
            fontWeight: 500,
        },
        h3: {
            fontSize: "4.5rem",
            lineHeight: 1.2,
        },
        h4: {
            fontSize: "3.5rem",
            fontWeight: 300,
            lineHeight: 1.2,
        },
        h5: {
            fontSize: "2.5rem",
            fontWeight: 300,
            lineHeight: 1.2,
        },
        h6: {
            fontSize: "1.5rem",
            fontWeight: 300,
            lineHeight: 1.2,
        },
        subtitle1: {
            fontWeight: 300,
            lineHeight: 1.5,
        },
        subtitle2: {
            fontWeight: 300,
            fontSize: "0.8rem",
            lineHeight: 1.5,
        },
        body1: {
            fontWeight: 300,
        },
        body2: {
            fontSize: "0.8rem",
            fontWeight: 300,
            lineHeight: 1.5,
        },
        caption: {
            fontSize: "0.8rem",
            fontWeight: 400,
            lineHeight: 1.5,
        },
        overline: {
            fontSize: "1rem",
            fontWeight: 500,
            lineHeight: 1.5,
        },
    },
    spacing: 8,
    shape: {
        borderRadius: 3,
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                    xs: {
                        maxWidth: "100%",
                    },
                    sm: {
                        maxWidth: "1080px",
                    },
                    md: {
                        maxWidth: "1440px",
                    },
                    lg: {
                        maxWidth: "1440px",
                    },
                    xl: {
                        maxWidth: "1440px",
                    },
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    xs: {
                        maxWidth: "100%",
                    },
                    sm: {
                        maxWidth: "1080px",
                    },
                    md: {
                        maxWidth: "1440px",
                    },
                    lg: {
                        maxWidth: "1440px",
                    },
                    xl: {
                        maxWidth: "1440px",
                    },
                },
            },
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    margin: "0px",
                    padding: "0px",
                    xs: {
                        maxWidth: "100%",
                    },
                    sm: {
                        maxWidth: "1080px",
                    },
                    md: {
                        maxWidth: "1440px",
                    },
                    lg: {
                        maxWidth: "1440px",
                    },
                    xl: {
                        maxWidth: "1440px",
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: "0.8rem",
                    height: "2.5rem",
                    lineHeight: 1.5,
                    textTransform: "none",
                    color: "#1e2833",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    gap: "0.5rem",
                    borderWidth: 2,
                },
            },
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    color: "#58B55D",
                },
            },
        },
    },
});

export default theme;
