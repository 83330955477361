import React, { createContext, useState, useContext } from "react";

interface HubContextType {
    hubState: string | null;
    setHubState: (hub: string | null) => void;
}

const defaultContextValue: HubContextType = {
    hubState: null,
    setHubState: () => {},
};

const HubContext = createContext<HubContextType>(defaultContextValue);

interface HubProviderProps {
    children: React.ReactNode;
}

export const HubProvider: React.FC<HubProviderProps> = ({ children }) => {
    const [hubState, setHubState] = useState<string | null>(null);

    return <HubContext.Provider value={{ hubState, setHubState }}>{children}</HubContext.Provider>;
};

export const useHubState = (): HubContextType => useContext(HubContext);
