import {
    useTheme,
    View,
    Text,
    Image,
    Heading,
    Button,
    TextField,
    Link,
    Authenticator,
    useAuthenticator,
    CheckboxField,
    Flex,
    Divider,
    I18n,
} from "identity/helpers/imports";
import { HeaderProps, FooterProps, Theme } from "../helpers/types";
import { IsConnectedToTenant } from "services/GetInvoiceData";

I18n.putVocabularies({
    en: {},
});

export const formFields = {  
    signIn: {},
    signUp: {
        given_name: {
            label: "First Name",
            placeholder: "First Name",
            required: true,
            order: 1,
        },
        family_name: {
            label: "Last Name",
            placeholder: "Last Name",
            required: true,
            order: 2,
        },
        nickname: {
            label: "Account #",
            placeholder: "Account #",
            required: true,
        },
    },
};

export const IdentityTheme: Theme = {
    name: "identity-theme",
    tokens: {
        components: {
            authenticator: {
                router: {
                    backgroundColor: { value: "transparent" },
                    borderStyle: { value: "none" },
                    boxShadow: { value: "none" },
                },
            },
            button: {
                fontWeight: { value: 600 },
                backgroundColor: { value: "transparent" },
                primary: {
                    backgroundColor: { value: "#58B55D" },
                    _hover: {
                        backgroundColor: { value: "#79c47d" },
                    },
                },
                link: {
                    color: { value: "#58B55D" },
                    _hover: {
                        color: { value: "#46914a" },
                        backgroundColor: { value: "transparent" },
                    },
                },
            },
            fieldcontrol: {
                borderRadius: { value: "8px" },
            },
            input: {
                fontSize: { value: "16px" },
            },
            checkbox: {
                button: {
                    _focus: {
                        outlineColor: { value: "none" },
                        borderColor: { value: "#58B55D" },
                        boxShadow: { value: "none" },
                    },
                },
                icon: {
                    backgroundColor: { value: "#58B55D" },
                    borderRadius: { value: "8px" },
                },
            },
        },
    },
};

const IdentityFlow = {
    Header({ logo, companyName }: HeaderProps) {
        return (
            <View
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "100px",
                }}
            >
                <Link href='/'>
                    <Image
                        alt={companyName + "Logo"}
                        src={logo}
                        maxWidth='156px'
                        maxHeight='66px'
                        marginBottom='20px'
                    />
                </Link>
            </View>
        );
    },
    Footer() {
        return <></>;
    },
    SignIn: {
        Header() {
            const { tokens } = useTheme();

            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >
                    Login
                </Heading>
            );
        },
        Footer: ({ rememberMe, setRememberMe }: FooterProps) => {
            const { toForgotPassword } = useAuthenticator();
            const { toSignUp } = useAuthenticator();

            return (
                <Flex
                    direction='column'
                    justifyContent='space-between'
                    alignItems='center'
                    width='100%'
                >
                    <Flex
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        width='100%'
                    >
                        <Flex></Flex>
                        {/*
                        <CheckboxField
                            label='Remember Me'
                            name='rememberMe'
                            value='yes'
                            checked={rememberMe}
                            onChange={(e) => setRememberMe(e.target.checked)}
                        />
                         */}
                        <Button
                            fontWeight='normal'
                            onClick={toForgotPassword}
                            size='small'
                            variation='link'
                            alignItems='flex-end'
                        >
                            Forgot Password?
                        </Button>
                    </Flex>
                    <Flex direction='column'>
                        <Text>
                            By continuing, you agree to Prism's{" "}
                            <Link style={{ color: "#58B55D" }}>Terms and Conditions</Link> and{" "}
                            <Link style={{ color: "#58B55D" }}>Privacy Policy.</Link>
                        </Text>
                        {IsConnectedToTenant() && (
                            <Text>
                                If you are an existing customer without a Web Account, please{" "}
                                <Link
                                    onClick={toSignUp}
                                    style={{ color: "#58B55D" }}
                                >
                                    Create an Account.
                                </Link>
                            </Text>
                        )}
                    </Flex>
                </Flex>
            );
        },
    },
    SignUp: {
        Header() {
            const { tokens } = useTheme();

            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >
                    Create Account
                </Heading>
            );
        },
        FormFields() {
            const { validationErrors } = useAuthenticator();

            return (
                <>
                    <Authenticator.SignUp.FormFields />
                    <View style={{ textAlign: "left" }}>
                        <Text>
                            By continuing, you agree to Prism's{" "}
                            <Link style={{ color: "#58B55D" }}>Terms and Conditions</Link> and{" "}
                            <Link style={{ color: "#58B55D" }}>Privacy Policy.</Link>
                        </Text>
                    </View>
                </>
            );
        },
        Footer() {
            const { toSignIn } = useAuthenticator();

            return (
                <View style={{ textAlign: "center", marginTop: "20px" }}>
                    <Text>
                        Already have an account?{" "}
                        <Link
                            onClick={toSignIn}
                            style={{ color: "#58B55D" }}
                        >
                            Login
                        </Link>
                    </Text>
                </View>
            );
        },
    },
    ForgotPassword: {
        Header() {
            return (
                <Flex direction='column'>
                    <Flex>
                        <Heading level={3}>Forgot Your Password?</Heading>
                    </Flex>
                    <Flex>
                        <Text>
                            Enter the email address associated with your account. Contact us if you
                            have additional questions: (800) 555-1234
                        </Text>
                    </Flex>
                </Flex>
            );
        },
        Footer() {
            const { toSignIn } = useAuthenticator();
            return (
                <>
                    <Divider style={{ color: "#fefefe" }} />
                    <Flex justifyContent='center'>
                        <Text>
                            New to Web Store?{" "}
                            <Link
                                onClick={toSignIn}
                                style={{ color: "#58B55D" }}
                            >
                                Create an Account.
                            </Link>
                        </Text>
                    </Flex>
                </>
            );
        },
    },
};

export default IdentityFlow;
