import { Divider, Theme, ThemeProvider } from "@aws-amplify/ui-react";

const theme: Theme = {
    name: "divider-theme",
    tokens: {
        components: {
            divider: {
                borderStyle: { value: "dotted" },
                borderColor: { value: "{colors.blue.80}" },
                borderWidth: { value: "{borderWidths.small}" },

                label: {
                    color: { value: "{colors.white}" },
                    backgroundColor: { value: "{colors.blue.80}" },
                },

                large: {
                    borderWidth: { value: "{borderWidths.large}" },
                },
            },
        },
    },
};

export const CustomDivider = () => (
    <ThemeProvider
        theme={theme}
        colorMode='light'
    >
        <Divider />
    </ThemeProvider>
);
